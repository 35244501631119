export default function SubscriptionModal({open, setOpen, loading, title, subtitle, icon, actionFunction, actionText}) {
  const handleModalClose = (e) => {
    if (e.target.classList.contains("subscription-plan-modal")) {
      setOpen(false);
    }
  };
    return (
        open && <div className="subscription-plan-modal"
        onClick={handleModalClose}
        
        >
        <div className="subscription-plan-modal__inner">
          <div className="subscription-plan-modal__inner__cancel">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="pointer"
              onClick={() => setOpen(false)}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.8999 1.49906C15.2999 1.64906 17.5499 2.84906 19.1999 4.49906C21.1499 6.59906 22.1999 9.14906 22.1999 12.1491C22.1999 14.5491 21.2999 16.7991 19.7999 18.7491C18.2999 20.5491 16.1999 21.8991 13.7999 22.3491C11.3999 22.7991 8.99994 22.4991 6.89994 21.2991C4.79994 20.0991 3.14994 18.2991 2.24994 16.0491C1.34994 13.7991 1.19994 11.2491 1.94994 8.99906C2.69994 6.59906 4.04994 4.64906 6.14994 3.29906C8.09994 1.94906 10.4999 1.34906 12.8999 1.49906ZM13.6499 20.8491C15.5999 20.3991 17.3999 19.3491 18.7499 17.6991C19.9499 16.0491 20.6999 14.0991 20.5499 11.9991C20.5499 9.59906 19.6499 7.19906 17.9999 5.54906C16.4999 4.04906 14.6999 3.14906 12.5999 2.99906C10.6499 2.84906 8.54994 3.29906 6.89994 4.49906C5.24994 5.69906 4.04994 7.34906 3.44994 9.44906C2.84994 11.3991 2.84994 13.4991 3.74994 15.4491C4.64994 17.3991 5.99994 18.8991 7.79994 19.9491C9.59994 20.9991 11.6999 21.2991 13.6499 20.8491ZM11.8499 11.2491L15.4499 7.49906L16.4999 8.54906L12.8999 12.2991L16.4999 16.0491L15.4499 17.0991L11.8499 13.3491L8.24994 17.0991L7.19994 16.0491L10.7999 12.2991L7.19994 8.54906L8.24994 7.49906L11.8499 11.2491Z"
                fill="#667185"
              />
            </svg>
          </div>

          <div className="subscription-plan-modal__inner__icon">
            {icon}
          </div>

          <div className="subscription-plan-modal__inner__title">
            {title}
          </div>
          <div className="subscription-plan-modal__inner__subtitle">
            {subtitle}
          </div>

          <button className="transparent bold" onClick={actionFunction} disabled={loading}>{loading ? "Loading..." : actionText}</button>
        </div>
      </div>
    )
}