import { useCallback, useEffect, useRef, useState } from "react";
import Search from "../../components/UserDashboard/Search";
import SideBar from "../../components/UserDashboard/SideBar";
import "../../styles/UserDashboard/CarbonCredit.scss";
import Chart from "chart.js/auto";
import {
  exchnageCcrt,
  getLatestWeekOffset,
} from "../../apis/UserDashboard/dashboard";
import { toast } from "react-toastify";
import Kyc from "../../components/UserDashboard/Kyc";
import { getKyc, getUserInfo } from "../../apis/General/users";
import { useSelector } from "react-redux";
import { balanceOf as greenBasicBalanceOf } from "../../instantiateTransaction/basicGreenProject";
import { balanceOf as infraBasicBalanceOf } from "../../instantiateTransaction/basicInfrastructureChange";
import { balanceOf as greenGovernanceBalanceOf } from "../../instantiateTransaction/governanceGreenProject";
import { balanceOf as infraGovernanceBalanceOf } from "../../instantiateTransaction/gorvernanceInfrastructureChange";
import {
  getDatabase,
  ref,
  onValue,
  orderByChild,
  equalTo,
  query,
} from "firebase/database";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "@uniswap/widgets/fonts.css";
import { ethers, formatEther } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
// import { SwapWidget } from "@uniswap/widgets";
import { useNavigate } from "react-router-dom";
import CloseCarbonToken from "../../Abi/CloseCarbonToken.json";
import Web3 from "web3";
import { Tooltip, Typography } from "@mui/material";
import * as echarts from 'echarts';

// import { UniswapProvider } from '@uniswap/sdk';

// const provider = new UniswapProvider(window.ethereum);

// const TOKEN_LIST = 'https://gateway.ipfs.io/ipns/tokens.uniswap.org'
// const provider = useActiveProvider()
// const JSON_RPC_URL = 'https://cloudflare-eth.com';
// const UNI = '0xAd3f964fec5505a0CE41e818372C8785F55685DB';

const CarbonCredit = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [weeklyOffsetDays, setWeeklyOffsetDays] = useState(null);
  const [weeklyOffset, setWeeklyoffset] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenTwo, setModalOpenTwo] = useState(false);
  const [modalOpenThree, setModalOpenThree] = useState(false);
  const [modalOpenFour, setModalOpenFour] = useState(false);
  const [modalOpenFive, setModalOpenFive] = useState(false);
  const [cardVisible, setCardVisible] = useState("");
  const [carbonCredit, setCarbonCredit] = useState(0);
  const [carbonCreditEvents, setCarbonCreditEvents] = useState([]);
  const [carbonMonthlyData, setCarbonMonthlyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locale, setLocale] = useState("en-US");
  const [exchange, setExchange] = useState(false);
  const [foundUser, setFoundUser] = useState(null);
  const [ccrt, setCcrt] = useState(0);
  const [amount, setAmount] = useState(9);
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [copied, setCopied] = useState(false);

  
  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => setAccount(accounts[0]))
        .catch((err) => {
          alert(
            "Please allow access to your MetaMask wallet to enable you exchange your CCRT"
          );
          console.error("User denied account access", err);
        });
    } else {
      console.error(
        "MetaMask is not installed. Please install it to use this app."
      );
    }
  }, []);

  const contractAddress = "0x4e9240604452d4454153bF59dE204E341186CC83";
  const contractABI = CloseCarbonToken.abi;

  const contract = web3
    ? new web3.eth.Contract(contractABI, contractAddress)
    : null;

  const burnTokens = async () => {
    setLoading(true);

    if (!amount || !contract || !account) return;

    try {
      await contract.methods.burn(ccrt * Math.pow(10, 18)).send({ from: account })
        .on('transactionHash', (hash) => {
          console.log('Transaction Hash:', hash);
        })
        .on('receipt', async (receipt) => {
          console.log('Receipt:', receipt);
      await handleExchnageCcrt();
      setLoading(false);
      })
      .on('error', (error) => {
        console.error('Error:', error);
        setLoading(false);

      });
    } catch (error) {
      console.error("Burning tokens failed", error);
      setLoading(false);
    }
  };

  // const connectors = useRef(null);
  // const focusConnectors = useCallback(() => connectors.current?.focus(), []);

  const handleModalClose = (e) => {
    if (e.target.classList.contains("trade_modal_container")) {
      setModalOpen(false);
    }
  };
  const handleModalCloseTwo = (e) => {
    if (e.target.classList.contains("swap-modal-container")) {
      setModalOpenTwo(false);
    }
  };
  const handleModalCloseThree = (e) => {
    if (loading) {
      return;
    }
    if (e.target.classList.contains("ccrtngn-modal")) {
      setModalOpenThree(false);
      setModalOpenFour(false);
    }
  };

  const handleErrorModalClose = (e) => {
    if (e.target.classList.contains("errorModal")) {
      setModalOpenFive(false);
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalOpenTwo = () => {
    setModalOpenTwo(true);
  };
  const handleModalOpenFour = () => {
    if (!ccrt) {
      alert("Please input the amount of CCRT you wish to exchange");
    } else if (ccrt > carbonCredit) {
      alert("Insufficient CCRT in wallet");
    } else {
      if (!foundUser?.bankDetails) {
        setModalOpenFive(true);
        setModalOpenThree(false);
        return;
      }
      setModalOpenThree(false);
      setModalOpenFour(true);
    }
  };
  const handleExchnageCcrt = async () => {
    if (!ccrt) {
      alert("Please input the amount of CCRT you wish to exchange");
    } else {
      const response = await exchnageCcrt(ccrt, auth?.token);
      console.log("response", response);
      if (response?.data?.statusCode === 200) {
        alert("Exchange successful");
        setModalOpenThree(false);
        setModalOpenFour(false);
        setExchange(false);
        setCcrt(0);
        getWalletBalance();
      } else {
        alert("Exchange unsuccessful. Please try again");
      }
    }
  };
  const handleGetLatestWeekOffset = async () => {
    const response = await getLatestWeekOffset(auth?.token);
    console.log("hyqwgef576", response);
    if (response?.data?.statusCode === 200) {
      const temp = response?.data?.message.map((item) => {
        const date = new Date(Number(item.createdAt));
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        return `${month} ${day}`;
      });
      setWeeklyOffsetDays(temp);
      const temp2 = response?.data?.message.map((item) =>
        Number(item.offset).toFixed(5)
      );
      setWeeklyoffset(temp2);
    } else {
      // toast.error("Something went wrong");
    }
  };

  const getWalletBalance = async () => {
    setLoading(true);
    try {
      const response = await getKyc(auth?.userInfo?.id, auth?.token);
      console.log("getKyc", response);
      if (
        response?.data?.message?.facilityCategory === "green" &&
        response?.data?.message?.subscriptionPlan === "basic"
      ) {
        const response2 = await greenBasicBalanceOf(
          response?.data?.message?.walletAddress
        );
        setCarbonCredit(ethers.formatEther(response2?.toString()));
        console.log("response2 alpha", Number(response2) / Math.pow(10, 18));
      } else if (
        response?.data?.message?.sustainabilitySolution ===
          "infrastructure-change" &&
        response?.data?.message?.subscriptionPlan === "basic"
      ) {
        const response2 = await infraBasicBalanceOf(
          response?.data?.message?.walletAddress
        );
        setCarbonCredit(ethers.formatEther(response2?.toString()));
      } else if (
        response?.data?.message?.facilityCategory === "green" &&
        response?.data?.message?.subscriptionPlan === "governance"
      ) {
        const response2 = await greenGovernanceBalanceOf(
          response?.data?.message?.walletAddress
        );
        setCarbonCredit(ethers.formatEther(response2?.toString()));
      } else if (
        response?.data?.message?.sustainabilitySolution ===
          "infrastructure-change" &&
        response?.data?.message?.subscriptionPlan === "governance"
      ) {
        const response2 = await infraGovernanceBalanceOf(
          response?.data?.message?.walletAddress
        );
        setCarbonCredit(ethers.formatEther(response2?.toString()));
      }else if (
        response?.data?.message?.subscriptionPlan === "business"
      ) {
        setCarbonCredit(0)
      }
       else {
        toast.error("Something went wrong");
      }
      const eventsQuery = query(
        tableRef,
        orderByChild("addressToRecord"),
        equalTo(response?.data?.message?.walletAddress)
      );
      return onValue(eventsQuery, (snapshot) => {
        if (snapshot.exists()) {
          const data = Object.values(snapshot.val());
          const filteredData = data.filter(
            (item) => item.type === "Carbon Credits Transferred"
          );
          console.log("filteredData", filteredData);
          const hello = getMonthlyTotals(filteredData, 2024);
          console.log("hello", hello);
          setCarbonCreditEvents(filteredData);
        } else {
          console.log(`No carbon credit data found for this user`);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.error("Something went wrong");
    }

    setLoading(false);
  };

  const fetchUserInfo = async () => {
    const response = await getUserInfo(auth?.userInfo?.id, auth?.token);
    console.log("getUser", response);
    setFoundUser(response?.data?.message);
  };

  useEffect(() => {
    handleGetLatestWeekOffset();
    getWalletBalance();
    fetchUserInfo();
  }, []);

  useEffect(() => {
    console.log("foundUser", foundUser);
  }, [foundUser]);

  useEffect(() => {
    if (weeklyOffset?.length > 0) {
      const ctxOffset = document.getElementById("myOffsetChart");

      const myOffsetChart = new Chart(ctxOffset, {
        type: "bar",
        data: {
          labels: weeklyOffsetDays,
          datasets: [
            {
              label:
                auth?.userInfo?.subscriptionPlan === "governance"
                  ? "NDCs-S Value"
                  : "Emission Reduction",
              data: weeklyOffset,
              borderWidth: 1,
              backgroundColor: "rgba(14, 125, 152, 0.25)",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  return `${tooltipItem?.label}`;
                },
                label: function (tooltipItem) {
                  return `${tooltipItem?.formattedValue}`;
                },
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });

      return () => {
        myOffsetChart.destroy();
      };
    }
  }, [weeklyOffset]);
  // useEffect(() => {
  //   const ctx = document.getElementById("myCarbonCreditChart");
  //   console.log("carbonMonthlyDatapop", carbonMonthlyData);

  //   const myCarbonCreditChart = new Chart(ctx, {
  //     type: "bar",
  //     data: {
  //       labels: [
  //         "Jan",
  //         "Feb",
  //         "Mar",
  //         "Apr",
  //         "May",
  //         "Jun",
  //         "Jul",
  //         "Aug",
  //         "Sep",
  //         "Oct",
  //         "Nov",
  //         "Dec",
  //       ],
  //       datasets: [
  //         {
  //           label: "CCRT Allocated",
  //           data: carbonMonthlyData,
  //           borderWidth: 1,
  //           backgroundColor: "#FA9874",
  //         },
  //       ],
  //     },
  //     options: {
  //       responsive: true,
  //       maintainAspectRatio: false,
  //       plugins: {
  //         tooltip: {
  //           callbacks: {
  //             label: function (tooltipItem) {
  //               return `${tooltipItem?.formattedValue}`;
  //             },
  //           },
  //         },
  //         legend: {
  //           labels: {
  //             color: "#FFF",
  //           },
  //         },
  //       },
  //       scales: {
  //         y: {
  //           ticks: {
  //             color: "#fff",
  //           },
  //           grid: {
  //             color: "#667185",
  //           },
  //           beginAtZero: true,
  //         },
  //         x: {
  //           ticks: {
  //             color: "#fff",
  //           },
  //           grid: {
  //             color: "#667185",
  //           },
  //         },
  //       },
  //     },
  //   });

  //   return () => {
  //     myCarbonCreditChart.destroy();
  //   };
  // }, [carbonMonthlyData]);

  useEffect(() => {
    window.Browser = {
      T: () => {},
    };
  }, [modalOpenTwo]);

  const db = getDatabase();
  const tableRef = ref(db, "events");

  const jsonRpcEndpoint = `https://polygon-mainnet.infura.io/v3/b455056c0c134223a663258c63b9f284"`;
  // const jsonRpcUrlMap = {
  //   137: "https://polygon-mainnet.infura.io/v3/b455056c0c134223a663258c63b9f284",
  // };

  const jsonRpcUrlMap = {
    1: "https://mainnet.infura.io/v3/5aa26fa9037c49a19eb0adea4910f81a",
    80001:
      "https://polygon-mumbai.infura.io/v3/5aa26fa9037c49a19eb0adea4910f81a",
  };

  function getMonthlyTotals(data, year) {
    console.log('data', data);
    
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const totals = {};

    months.forEach((month) => {
      totals[month] = {
        month,
        total: 0,
      };
    });

    data.forEach((item) => {
      const date = new Date(item.timestamp)

      if (date.getFullYear() === year) {
        const month = date.toLocaleString("default", { month: "short" });
        totals[month].total += parseInt(item.creditIssued);
      }
    });

    console.log("totals", totals);

    setCarbonMonthlyData(Object.values(totals).map((item) => item.total));
  }

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
        setCopied(true);
        // Optionally, you can add some user feedback here
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };


  useEffect(() => {
   if(weeklyOffset?.length > 0) {
    var chartDom = document.getElementById('myOffsetChart');
    var myChart = echarts.init(chartDom);
    var option;
    
    option = {
      title: {
        text: 'Emission Reduction'
      },
      tooltip: {},
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: weeklyOffsetDays
      },
      yAxis: {
        type: 'value',
        data: ['400tco2', '100tco2', '200tco2', '160tco2', '200tco2', '400tco2']
      },
      color: "#DEEFE6",
      series: [
        {
          name: 'Emission Reduction',
          data: weeklyOffset,
          type: 'line',
          smooth: true,
          areaStyle: {}
        }
      ]
    };
    
    option && myChart.setOption(option);
   }
}, [weeklyOffset])


useEffect(() => {
if(carbonMonthlyData?.length > 0) {
    // Create the echarts instance
var chartDom = document.getElementById('myCarbonCreditChart');
var myChart = echarts.init(chartDom);
var option;

option = {
title: {
  text: 'Carbon Credit Allocated'
},
tooltip: {},
xAxis: {
  type: 'category',
  data: ["Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",]
},
yAxis: {
  type: 'value',
  data: ['40k', '10k', '20k', '16k', '20k', '40k', '10k', '20k', '16k', '40k', '10k', '20k']
},
color: "#00AED9",
series: [
  {
    name: 'Carbon Credit Allocated',
    data: carbonMonthlyData,
    type: 'bar',
    label: {
      show: true,
      position: 'inside'
    },
  }
]
};

option && myChart.setOption(option);
}

}, [carbonMonthlyData])

  return (
    <div className="carbon_credit">
      <div className="sidebar_container">
        <SideBar />
      </div>
      <div className="main">
        <div className="search_container">
          <Search />
        </div>
        
        <div className="carbon_credit__contract-address">
          CCRT address:{" "}
          <Tooltip
                    title={
                      <Typography variant="h6">
                        {copied ? "Copied" : "Copy"}
                      </Typography>
                    }
                  >
          <span onClick={() => handleCopy("0x80A9e51F4e883Cf51aDc203911d9C0F9BCf48f2F")}>
            <div>0x80A9e51F4e883Cf51aDc203911d9C0F9BCf48f2F</div>
            {copied ? (
             <svg viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            ) : (
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            )}{" "}
          </span>
          </Tooltip>
        </div>
        <div className="carbon_credit__contract-address">
          CloseCarbon USDT Trading Address:{" "}
          <Tooltip
                    title={
                      <Typography variant="h6">
                        {copied ? "Copied" : "Copy"}
                      </Typography>
                    }
                  >
          <span onClick={() => handleCopy("0x10d90Ee2930369B72db024971FF7a85deCB95F09")}>
            <div>0x10d90Ee2930369B72db024971FF7a85deCB95F09</div>
            {copied ? (
             <svg viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.5 12.5L10.167 17L19.5 8" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            ) : (
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            )}{" "}
          </span>
          </Tooltip>
        </div>
        <div
        
          className={
            !loading && carbonCredit <= 0 ? "wallet wallet-empty" : "wallet"
          }
        >

          {/* <div className="subtitle">
                            CCRT
                        </div> */}
          {!loading && carbonCredit > 0 && (
            <div className="title">CloseCarbon Reduction Tonne (CCRT)</div>
          )}
          {!loading && carbonCredit > 0 && (
            <div className="subtitle">{carbonCredit}</div>
          )}
          {loading && (
            <div className="title">
              <Skeleton
                width={100}
                height={20}
                borderRadius={4}
                highlightColor="#ccc"
              />
            </div>
          )}
          {loading && (
            <Skeleton
              // width={100}
              highlightColor="#ccc"
              height={40}
              borderRadius={8}
              style={{ marginTop: "16px" }}
            />
          )}
          {/* <button>Connect Wallet</button> */}
          {!loading && carbonCredit > 0 && (
            <button
              className={
                carbonCredit <= 0
                  ? "transparent-empty transparent"
                  : "transparent"
              }
              onClick={() => setExchange(!exchange)}
              // disabled={carbonCredit <= 0}
            >
              Exchange
            </button>
          )}

          {exchange && (
            <div className="wallet__exchange">
              <div onClick={() => setModalOpenThree(true)}>
                Exchange CCRT for NGN
              </div>
              <div 
              // onClick={handleModalOpenTwo}
              onClick={() => window.open("https://app.uniswap.org/")}
              >Exchange CCRT for USDC</div>
            </div>
         )}

          {!loading && carbonCredit <= 0 ? (
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.1748 31.9901L32.0028 29.1631L44.0218 41.1821L41.1938 44.0091L29.1748 31.9901Z"
                fill="#616161"
              />
              <path
                d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z"
                fill="#616161"
              />
              <path
                d="M32.4502 35.34L35.2772 32.512L43.9732 41.208L41.1452 44.036L32.4502 35.34Z"
                fill="#37474F"
              />
              <path
                d="M20 33C27.1797 33 33 27.1797 33 20C33 12.8203 27.1797 7 20 7C12.8203 7 7 12.8203 7 20C7 27.1797 12.8203 33 20 33Z"
                fill="#00AED9"
              />
              <path
                d="M26.8999 14.2C25.1999 12.2 22.6999 11 19.9999 11C17.2999 11 14.7999 12.2 13.0999 14.2C12.6999 14.6 12.7999 15.3 13.1999 15.6C13.5999 16 14.2999 15.9 14.5999 15.5C15.9999 13.9 17.8999 13 19.9999 13C22.0999 13 23.9999 13.9 25.3999 15.5C25.5999 15.7 25.8999 15.9 26.1999 15.9C26.3999 15.9 26.6999 15.8 26.7999 15.7C27.1999 15.3 27.1999 14.6 26.8999 14.2Z"
                fill="#BBDEFB"
              />
            </svg>
          ) : (
            ""
          )}
          {!loading && carbonCredit <= 0 ? (
            <div>You’re yet to earn CCRT</div>
          ) : (
            ""
          )}
          {/* {isConnected ? <ConnectButton /> : <ConnectButton />} */}
        </div>
        <div className="carbon_credit_group">
          <div className="carbon_credit_table_container">
            <div className={cardVisible === "table" ? "tag " : "tag no_tag"}>
              Keep tracks of your CCRT issuance
            </div>
            <div className="carbon_credit_table_container__title">
              CCRT History{" "}
              <svg
                className="pointer"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onMouseEnter={() => setCardVisible("table")}
                onMouseLeave={() => setCardVisible("")}
              >
                <path
                  d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                  stroke="#98A2B3"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 5.33337H8.00667V5.34004H8V5.33337Z"
                  stroke="#98A2B3"
                  stroke-width="2.25"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 8V10.6667"
                  stroke="#98A2B3"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="carbon_credit_table">
              {!loading && carbonCreditEvents?.length > 0 && (
                <div className="table_header">
                  <div>CCRT</div>
                  <div>Project</div>
                  <div>Date</div>
                  <div>Time</div>
                </div>
              )}
              {loading && (
                <>
                  <Skeleton
                    baseColor="#fafafa"
                    highlightColor="#ccc"
                    height={58}
                    count={3}
                    style={{ marginBottom: "10px" }}
                    borderRadius={0}
                  />
                </>
              )}
              {carbonCreditEvents?.map((event) => (
                <div className="table_body">
                  <td>{Number(event?.creditIssued)?.toFixed(4)}</td>
                  <td>
                    {event?.scenario === "green"
                      ? "green project"
                      : event?.scenario}
                  </td>
                  <td>
                    {moment(parseInt(event?.timestamp)).format(
                      "Do MMM YYYY"
                    )}
                  </td>
                  <td>
                    {moment(parseInt(event?.timestamp)).format(
                      "hh:mm A"
                    )}
                  </td>
                </div>
              ))}
              {!loading && carbonCreditEvents?.length < 1 && (
                <div className="carbon-credit-table-empty-state">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.1748 31.9901L32.0028 29.1631L44.0218 41.1821L41.1938 44.0091L29.1748 31.9901Z"
                      fill="#616161"
                    />
                    <path
                      d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z"
                      fill="#616161"
                    />
                    <path
                      d="M32.4502 35.34L35.2772 32.512L43.9732 41.208L41.1452 44.036L32.4502 35.34Z"
                      fill="#37474F"
                    />
                    <path
                      d="M20 33C27.1797 33 33 27.1797 33 20C33 12.8203 27.1797 7 20 7C12.8203 7 7 12.8203 7 20C7 27.1797 12.8203 33 20 33Z"
                      fill="#00AED9"
                    />
                    <path
                      d="M26.8999 14.2C25.1999 12.2 22.6999 11 19.9999 11C17.2999 11 14.7999 12.2 13.0999 14.2C12.6999 14.6 12.7999 15.3 13.1999 15.6C13.5999 16 14.2999 15.9 14.5999 15.5C15.9999 13.9 17.8999 13 19.9999 13C22.0999 13 23.9999 13.9 25.3999 15.5C25.5999 15.7 25.8999 15.9 26.1999 15.9C26.3999 15.9 26.6999 15.8 26.7999 15.7C27.1999 15.3 27.1999 14.6 26.8999 14.2Z"
                      fill="#BBDEFB"
                    />
                  </svg>
                  Nothing to see here
                </div>
              )}
            </div>
          </div>
          <div className="offset_chart">
            <div
              className="trigger pointer"
              onMouseEnter={() => setCardVisible("chart1")}
              onMouseLeave={() => setCardVisible("")}
            ></div>
            <div
              className={
                cardVisible === "chart1"
                  ? "tag chart_tag"
                  : "tag chart_tag no_tag"
              }
            >
              Shows your{" "}
              {auth?.userInfo?.subscriptionPlan === "governance"
                ? "NDCs-S contribution"
                : "Emission Reduction"}{" "}
              over the last week
            </div>
            <div id="myOffsetChart"></div>
            {weeklyOffset?.length < 1 && (
              <div className="offset-chart-empty-state">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.1748 31.9901L32.0028 29.1631L44.0218 41.1821L41.1938 44.0091L29.1748 31.9901Z"
                    fill="#616161"
                  />
                  <path
                    d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z"
                    fill="#616161"
                  />
                  <path
                    d="M32.4502 35.34L35.2772 32.512L43.9732 41.208L41.1452 44.036L32.4502 35.34Z"
                    fill="#37474F"
                  />
                  <path
                    d="M20 33C27.1797 33 33 27.1797 33 20C33 12.8203 27.1797 7 20 7C12.8203 7 7 12.8203 7 20C7 27.1797 12.8203 33 20 33Z"
                    fill="#00AED9"
                  />
                  <path
                    d="M26.8999 14.2C25.1999 12.2 22.6999 11 19.9999 11C17.2999 11 14.7999 12.2 13.0999 14.2C12.6999 14.6 12.7999 15.3 13.1999 15.6C13.5999 16 14.2999 15.9 14.5999 15.5C15.9999 13.9 17.8999 13 19.9999 13C22.0999 13 23.9999 13.9 25.3999 15.5C25.5999 15.7 25.8999 15.9 26.1999 15.9C26.3999 15.9 26.6999 15.8 26.7999 15.7C27.1999 15.3 27.1999 14.6 26.8999 14.2Z"
                    fill="#BBDEFB"
                  />
                </svg>
                No CO2 emission yet
              </div>
            )}
          </div>
        </div>
        <div
          className={
            carbonMonthlyData?.length < 1 ? "chart chart-empty" : "chart"
          }
        >
          <div
            className="trigger pointer"
            onMouseEnter={() => setCardVisible("chart2")}
            onMouseLeave={() => setCardVisible("")}
          ></div>
          <div
            className={
              cardVisible === "chart2"
                ? "tag chart_tag"
                : "tag chart_tag no_tag"
            }
          >
            Shows the CCRT issued per month
          </div>
          {carbonMonthlyData?.length > 1 && (
            <div id="myCarbonCreditChart"></div>
           )} 
           {carbonMonthlyData?.length < 1 && (
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.1748 31.9901L32.0028 29.1631L44.0218 41.1821L41.1938 44.0091L29.1748 31.9901Z"
                fill="#616161"
              />
              <path
                d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z"
                fill="#616161"
              />
              <path
                d="M32.4502 35.34L35.2772 32.512L43.9732 41.208L41.1452 44.036L32.4502 35.34Z"
                fill="#37474F"
              />
              <path
                d="M20 33C27.1797 33 33 27.1797 33 20C33 12.8203 27.1797 7 20 7C12.8203 7 7 12.8203 7 20C7 27.1797 12.8203 33 20 33Z"
                fill="#00AED9"
              />
              <path
                d="M26.8999 14.2C25.1999 12.2 22.6999 11 19.9999 11C17.2999 11 14.7999 12.2 13.0999 14.2C12.6999 14.6 12.7999 15.3 13.1999 15.6C13.5999 16 14.2999 15.9 14.5999 15.5C15.9999 13.9 17.8999 13 19.9999 13C22.0999 13 23.9999 13.9 25.3999 15.5C25.5999 15.7 25.8999 15.9 26.1999 15.9C26.3999 15.9 26.6999 15.8 26.7999 15.7C27.1999 15.3 27.1999 14.6 26.8999 14.2Z"
                fill="#BBDEFB"
              />
            </svg>
          )}
          {carbonMonthlyData?.length < 1 && "Nothing to see here"}
        </div>
      </div>

      {modalOpen && (
        <div className="trade_modal_container" onClick={handleModalClose}>
          <div className="trade_modal">
            <button
              className="trade_grid uni"
              onClick={() => window.open("https://app.uniswap.org/")}
            >
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 168.3 193.8"
                style={{ enableBackground: "new 0 0 168.3 193.8" }}
                xmlSpace="preserve"
              >
                <path
                  class="st0"
                  fill="#FF007A"
                  d="M66,44.1c-2.1-0.3-2.2-0.4-1.2-0.5c1.9-0.3,6.3,0.1,9.4,0.8c7.2,1.7,13.7,6.1,20.6,13.8l1.8,2.1l2.6-0.4
	c11.1-1.8,22.5-0.4,32,4c2.6,1.2,6.7,3.6,7.2,4.2c0.2,0.2,0.5,1.5,0.7,2.8c0.7,4.7,0.4,8.2-1.1,10.9c-0.8,1.5-0.8,1.9-0.3,3.2
	c0.4,1,1.6,1.7,2.7,1.7c2.4,0,4.9-3.8,6.1-9.1l0.5-2.1l0.9,1c5.1,5.7,9.1,13.6,9.7,19.2l0.2,1.5l-0.9-1.3c-1.5-2.3-2.9-3.8-4.8-5.1
	c-3.4-2.3-7-3-16.5-3.5c-8.6-0.5-13.5-1.2-18.3-2.8c-8.2-2.7-12.4-6.2-22.1-19.1c-4.3-5.7-7-8.8-9.7-11.4
	C79.6,48.3,73.7,45.3,66,44.1z"
                />
                <path
                  class="st0"
                  fill="#FF007A"
                  d="M140.5,56.8c0.2-3.8,0.7-6.3,1.8-8.6c0.4-0.9,0.8-1.7,0.9-1.7c0.1,0-0.1,0.7-0.4,1.5c-0.8,2.2-0.9,5.3-0.4,8.8
	c0.7,4.5,1,5.1,5.8,10c2.2,2.3,4.8,5.2,5.8,6.4l1.7,2.2l-1.7-1.6c-2.1-2-6.9-5.8-8-6.3c-0.7-0.4-0.8-0.4-1.3,0.1
	c-0.4,0.4-0.5,1-0.5,3.9c-0.1,4.5-0.7,7.3-2.2,10.2c-0.8,1.5-0.9,1.2-0.2-0.5c0.5-1.3,0.6-1.9,0.6-6.2c0-8.7-1-10.8-7.1-14.3
	c-1.5-0.9-4.1-2.2-5.6-2.9c-1.6-0.7-2.8-1.3-2.7-1.3c0.2-0.2,6.1,1.5,8.4,2.5c3.5,1.4,4.1,1.5,4.5,1.4
	C140.2,60.1,140.4,59.3,140.5,56.8z"
                />
                <path
                  class="st0"
                  fill="#FF007A"
                  d="M70.1,71.7c-4.2-5.8-6.9-14.8-6.3-21.5l0.2-2.1l1,0.2c1.8,0.3,4.9,1.5,6.4,2.4c4,2.4,5.8,5.7,7.5,13.9
	c0.5,2.4,1.2,5.2,1.5,6.1c0.5,1.5,2.4,5,4,7.2c1.1,1.6,0.4,2.4-2.1,2.2C78.5,79.7,73.4,76.2,70.1,71.7z"
                />
                <path
                  class="st0"
                  fill="#FF007A"
                  d="M135.4,115.2c-19.8-8-26.8-14.9-26.8-26.6c0-1.7,0.1-3.1,0.1-3.1c0.1,0,0.8,0.6,1.7,1.3c4,3.2,8.5,4.6,21,6.4
	c7.3,1.1,11.5,1.9,15.3,3.2c12.1,4,19.6,12.2,21.4,23.3c0.5,3.2,0.2,9.3-0.6,12.5c-0.7,2.5-2.7,7.1-3.2,7.2c-0.1,0-0.3-0.5-0.3-1.3
	c-0.2-4.2-2.3-8.2-5.8-11.3C154,123.2,148.6,120.5,135.4,115.2z"
                />
                <path
                  class="st0"
                  fill="#FF007A"
                  d="M121.4,118.5c-0.2-1.5-0.7-3.4-1-4.2l-0.5-1.5l0.9,1.1c1.3,1.5,2.3,3.3,3.2,5.8c0.7,1.9,0.7,2.5,0.7,5.6
	c0,3-0.1,3.7-0.7,5.4c-1,2.7-2.2,4.6-4.2,6.7c-3.6,3.7-8.3,5.7-15,6.6c-1.2,0.1-4.6,0.4-7.6,0.6c-7.5,0.4-12.5,1.2-17,2.8
	c-0.6,0.2-1.2,0.4-1.3,0.3c-0.2-0.2,2.9-2,5.4-3.2c3.5-1.7,7.1-2.6,15-4c3.9-0.6,7.9-1.4,8.9-1.8C118.1,135.6,123,127.9,121.4,118.5
	z"
                />
                <path
                  class="st0"
                  fill="#FF007A"
                  d="M130.5,134.6c-2.6-5.7-3.2-11.1-1.8-16.2c0.2-0.5,0.4-1,0.6-1c0.2,0,0.8,0.3,1.4,0.7c1.2,0.8,3.7,2.2,10.1,5.7
	c8.1,4.4,12.7,7.8,15.9,11.7c2.8,3.4,4.5,7.3,5.3,12.1c0.5,2.7,0.2,9.2-0.5,11.9c-2.2,8.5-7.2,15.3-14.5,19.2c-1.1,0.6-2,1-2.1,1
	c-0.1,0,0.3-1,0.9-2.2c2.4-5.1,2.7-10,0.9-15.5c-1.1-3.4-3.4-7.5-8-14.4C133.2,139.6,131.9,137.5,130.5,134.6z"
                />
                <path
                  class="st0"
                  fill="#FF007A"
                  d="M56,165.2c7.4-6.2,16.5-10.6,24.9-12c3.6-0.6,9.6-0.4,12.9,0.5c5.3,1.4,10.1,4.4,12.6,8.1
	c2.4,3.6,3.5,6.7,4.6,13.6c0.4,2.7,0.9,5.5,1,6.1c0.8,3.6,2.4,6.4,4.4,7.9c3.1,2.3,8.5,2.4,13.8,0.4c0.9-0.3,1.7-0.6,1.7-0.5
	c0.2,0.2-2.5,2-4.3,2.9c-2.5,1.3-4.5,1.7-7.2,1.7c-4.8,0-8.9-2.5-12.2-7.5c-0.7-1-2.1-3.9-3.3-6.6c-3.5-8.1-5.3-10.5-9.4-13.2
	c-3.6-2.3-8.2-2.8-11.7-1.1c-4.6,2.2-5.8,8.1-2.6,11.7c1.3,1.5,3.7,2.7,5.7,3c3.7,0.5,6.9-2.4,6.9-6.1c0-2.4-0.9-3.8-3.3-4.9
	c-3.2-1.4-6.7,0.2-6.6,3.3c0,1.3,0.6,2.1,1.9,2.7c0.8,0.4,0.8,0.4,0.2,0.3c-2.9-0.6-3.6-4.2-1.3-6.5c2.8-2.8,8.7-1.6,10.7,2.3
	c0.8,1.6,0.9,4.8,0.2,6.8c-1.7,4.4-6.5,6.7-11.4,5.4c-3.3-0.9-4.7-1.8-8.7-5.9c-7-7.2-9.7-8.6-19.7-10.1l-1.9-0.3L56,165.2z"
                />
                <path
                  class="st1"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#FF007A"
                  d="M3.4,4.3c23.3,28.3,59.2,72.3,61,74.7c1.5,2,0.9,3.9-1.6,5.3c-1.4,0.8-4.3,1.6-5.7,1.6c-1.6,0-3.5-0.8-4.8-2.1
	c-0.9-0.9-4.8-6.6-13.6-20.3c-6.7-10.5-12.4-19.2-12.5-19.3C25.8,44,25.8,44,38,65.8C45.7,79.5,48.2,84.4,48.2,85c0,1.3-0.4,2-2,3.8
	c-2.7,3-3.9,6.4-4.8,13.5c-1,7.9-3.7,13.5-11.4,23c-4.5,5.6-5.2,6.6-6.3,8.9c-1.4,2.8-1.8,4.4-2,8c-0.2,3.8,0.2,6.2,1.3,9.8
	c1,3.2,2.1,5.3,4.8,9.4c2.3,3.6,3.7,6.3,3.7,7.3c0,0.8,0.2,0.8,3.8,0c8.6-2,15.7-5.4,19.6-9.6c2.4-2.6,3-4,3-7.6
	c0-2.3-0.1-2.8-0.7-4.2c-1-2.2-2.9-4-7-6.8c-5.4-3.7-7.7-6.7-8.3-10.7c-0.5-3.4,0.1-5.7,3.1-12c3.1-6.5,3.9-9.2,4.4-15.8
	c0.3-4.2,0.8-5.9,2-7.2c1.3-1.4,2.4-1.9,5.5-2.3c5.1-0.7,8.4-2,11-4.5c2.3-2.1,3.3-4.2,3.4-7.3l0.1-2.3L70.1,77C65.4,71.6,0.3,0,0,0
	C-0.1,0,1.5,1.9,3.4,4.3z M34.1,146.5c1.1-1.9,0.5-4.3-1.3-5.5c-1.7-1.1-4.3-0.6-4.3,0.9c0,0.4,0.2,0.8,0.8,1c0.9,0.5,1,1,0.3,2.1
	c-0.7,1.1-0.7,2.1,0.2,2.8C31.2,148.9,33.1,148.3,34.1,146.5z"
                />
                <path
                  class="st1"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#FF007A"
                  d="M74.6,93.9c-2.4,0.7-4.7,3.3-5.4,5.9c-0.4,1.6-0.2,4.5,0.5,5.4c1.1,1.4,2.1,1.8,4.9,1.8
	c5.5,0,10.2-2.4,10.7-5.3c0.5-2.4-1.6-5.7-4.5-7.2C79.3,93.7,76.2,93.4,74.6,93.9z M81,98.9c0.8-1.2,0.5-2.5-1-3.4
	c-2.7-1.7-6.8-0.3-6.8,2.3c0,1.3,2.1,2.7,4.1,2.7C78.6,100.5,80.4,99.7,81,98.9z"
                />
              </svg>

              <div>Trade on Uniswap</div>
            </button>
            <button
              className="trade_grid sushi"
              onClick={() => window.open("https://www.sushi.com/swap")}
            >
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 24 24"
                style={{ enableBackground: "new 0 0 24 24" }}
                xmlSpace="preserve"
              >
                <g>
                  <defs>
                    <rect id="SVGID_1_" width="24" height="24" />
                  </defs>
                  <clipPath id="SVGID_2_">
                    <use
                      xlinkHref="#SVGID_1_"
                      style={{ overflow: "visible" }}
                    />
                  </clipPath>
                  <g class="st0" clip-path="url(#SVGID_2_)">
                    <linearGradient
                      id="SVGID_3_"
                      gradientUnits="userSpaceOnUse"
                      x1="20.6442"
                      y1="1011.5057"
                      x2="24.3328"
                      y2="998.8395"
                      gradientTransform="matrix(1 0 0 -1 -12 1012)"
                    >
                      <stop offset="0" style={{ stopColor: "#03B8FF" }} />
                      <stop offset="1" style={{ stopColor: "#FA52A0" }} />
                    </linearGradient>
                    <path
                      class="st1"
                      fill="url(#SVGID_3_)"
                      d="M5,2.3L23.6,15l-4.6,6.8L0.4,9L5,2.3z"
                    />

                    <linearGradient
                      id="SVGID_4_"
                      gradientUnits="userSpaceOnUse"
                      x1="23.6818"
                      y1="1012.3904"
                      x2="27.3705"
                      y2="999.7241"
                      gradientTransform="matrix(1 0 0 -1 -12 1012)"
                    >
                      <stop offset="0" style={{ stopColor: "#03B8FF" }} />
                      <stop offset="1" style={{ stopColor: "#FA52A0" }} />
                    </linearGradient>
                    <path
                      class="st2"
                      fill="url(#SVGID_4_)"
                      d="M23.6,15c-1.6,2.3-7,1.4-12.1-2.2C6.3,9.3,3.5,4.6,5,2.3c1.6-2.3,7-1.4,12.1,2.2C22.3,7.9,25.1,12.7,23.6,15z
			"
                    />

                    <linearGradient
                      id="SVGID_5_"
                      gradientUnits="userSpaceOnUse"
                      x1="17.616"
                      y1="1010.6239"
                      x2="21.3047"
                      y2="997.9576"
                      gradientTransform="matrix(1 0 0 -1 -12 1012)"
                    >
                      <stop offset="0" style={{ stopColor: "#03B8FF" }} />
                      <stop offset="1" style={{ stopColor: "#FA52A0" }} />
                    </linearGradient>
                    <path
                      class="st3"
                      fill="url(#SVGID_5_)"
                      d="M19,21.7c-1.6,2.3-7,1.4-12.1-2.2s-8-8.2-6.4-10.6c1.6-2.3,7-1.4,12.1,2.2C17.7,14.7,20.5,19.4,19,21.7z"
                    />
                    <path
                      class="st4"
                      fill="#0E0F23"
                      d="M23.6,15C23.6,15,23.6,15,23.6,15l-4.6,6.8l0,0c-1.6,2.3-7,1.3-12.1-2.2c-1-0.7-1.9-1.4-2.8-2.2
			c0.7-0.1,1.6-0.5,2.5-1.5c1.6-1.7,2.4-2.1,3.1-2c0.7,0,1.5,0.7,2.8,2.4c1.3,1.7,3.1,2.2,4.2,1.3c0.1-0.1,0.2-0.1,0.3-0.2
			c0.9-0.7,1.2-1,2.9-4.2c0.4-0.8,1.8-2.1,3.7-1.5C24.1,13,24.1,14.1,23.6,15z"
                    />
                    <path
                      class="st5"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="#FFFFFF"
                      d="M22.9,14.6c-1.4,2-6.3,1-11-2.3C7.1,9,4.3,4.8,5.7,2.8s6.3-1,11,2.3S24.2,12.6,22.9,14.6z M18.5,11.6
			c-0.7,1-3.1,0.5-5.5-1.1c-2.3-1.6-3.7-3.7-3-4.7c0.7-1,3.1-0.5,5.5,1.1C17.8,8.5,19.2,10.6,18.5,11.6z"
                    />
                    <path
                      class="st6"
                      fill="#FFFFFF"
                      d="M4.6,4.6c0-0.1-0.1-0.2-0.2-0.1S4.2,4.6,4.2,4.7C4.3,5,4.4,5.2,4.4,5.4c0,0.1,0.1,0.2,0.2,0.1
			c0.1,0,0.2-0.1,0.1-0.2C4.7,5.1,4.7,4.9,4.6,4.6z"
                    />
                    <path
                      class="st6"
                      fill="#FFFFFF"
                      d="M5.1,6.2C5.1,6.1,5,6,4.9,6.1S4.8,6.2,4.8,6.3c1.1,2.5,3.4,5.2,6.4,7.2c0.1,0.1,0.2,0,0.3,0
			c0.1-0.1,0-0.2,0-0.3C8.4,11.2,6.2,8.6,5.1,6.2z"
                    />
                    <path
                      class="st6"
                      fill="#FFFFFF"
                      d="M17.2,16c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.2,0.1,0.2c0.3,0.1,0.7,0.2,1,0.3c0.1,0,0.2,0,0.2-0.1
			c0-0.1,0-0.2-0.1-0.2C17.9,16.2,17.5,16.1,17.2,16z"
                    />
                    <path
                      class="st6"
                      fill="#FFFFFF"
                      d="M19,16.4c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.8,0.1,1.7,0.2,2.4,0.1c0.1,0,0.2-0.1,0.2-0.2
			c0-0.1-0.1-0.2-0.2-0.2C20.6,16.6,19.8,16.5,19,16.4z"
                    />
                  </g>
                </g>
              </svg>

              <div>Trade on Sushiswap</div>
            </button>
            <button
              className="trade_grid pancake"
              onClick={() => window.open("https://pancakeswap.finance/swap")}
            >
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 31.7 32"
                style={{ enableBackground: "new 0 0 31.7 32" }}
                xmlSpace="preserve"
              >
                <path
                  class="st0"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#633001"
                  d="M5.7,5C5.2,2.4,7.2,0,9.8,0c2.3,0,4.2,1.9,4.2,4.2v5.2c0.6,0,1.2-0.1,1.8-0.1c0.6,0,1.1,0,1.7,0.1V4.2
	c0-2.3,1.9-4.2,4.2-4.2c2.6,0,4.6,2.4,4.2,5l-1.1,6.1c3.9,1.7,6.9,4.7,6.9,8.4v2.3c0,3.1-2,5.7-4.9,7.4c-2.9,1.8-6.7,2.8-11,2.8
	s-8.1-1-11-2.8C2,27.5,0,24.9,0,21.8v-2.3c0-3.7,2.9-6.7,6.8-8.4L5.7,5z M23.3,11.9l1.3-7.2c0.3-1.8-1-3.5-2.9-3.5
	c-1.6,0-2.9,1.3-2.9,2.9v6.6c-0.4-0.1-0.9-0.1-1.3-0.1c-0.6,0-1.1-0.1-1.7-0.1c-0.6,0-1.2,0-1.8,0.1c-0.4,0-0.9,0.1-1.3,0.1V4.2
	c0-1.6-1.3-2.9-2.9-2.9C8,1.3,6.6,3,7,4.8L8.3,12c-4.2,1.6-7,4.4-7,7.6v2.3c0,4.9,6.5,8.9,14.5,8.9c8,0,14.5-4,14.5-8.9v-2.3
	C30.4,16.3,27.6,13.5,23.3,11.9z"
                />
                <path
                  class="st1"
                  fill="#FEDC90"
                  d="M30.4,21.8c0,4.9-6.5,8.9-14.5,8.9c-8,0-14.5-4-14.5-8.9v-2.3h29.1V21.8z"
                />
                <path
                  class="st2"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#D1884F"
                  d="M7,4.8C6.6,3,8,1.3,9.8,1.3c1.6,0,2.9,1.3,2.9,2.9v6.6c1-0.1,2-0.2,3.1-0.2c1,0,2,0.1,3,0.2V4.2
	c0-1.6,1.3-2.9,2.9-2.9c1.8,0,3.2,1.7,2.9,3.5l-1.3,7.2c4.2,1.6,7.1,4.4,7.1,7.6c0,4.9-6.5,8.9-14.5,8.9c-8,0-14.5-4-14.5-8.9
	c0-3.2,2.8-6,7-7.6L7,4.8z"
                />
                <path
                  class="st3"
                  fill="#633001"
                  d="M11.8,18.9c0,1.3-0.7,2.4-1.6,2.4c-0.9,0-1.6-1.1-1.6-2.4s0.7-2.4,1.6-2.4C11.1,16.5,11.8,17.6,11.8,18.9z"
                />
                <path
                  class="st3"
                  fill="#633001"
                  d="M22.9,18.9c0,1.3-0.7,2.4-1.6,2.4c-0.9,0-1.6-1.1-1.6-2.4s0.7-2.4,1.6-2.4C22.2,16.5,22.9,17.6,22.9,18.9z"
                />
              </svg>
              <div>Trade on Pancakeswap</div>
            </button>
          </div>
        </div>
      )}

      {modalOpenTwo && (
        <div className="swap-modal-container" onClick={handleModalCloseTwo}>
          <div className="swap-modal">
            <div>
              {/* <button onClick={connectWallet}>Connect Wallet</button> */}
            </div>
            {/* <SwapWidget jsonRpcEndpoint={jsonRpcEndpoint} width={"100%"} /> */}
            {/* <SwapWidget
              tokenList={"https://ipfs.io/ipns/tokens.uniswap.org"}
              width={"100%"}
            /> */}
              {/* <SwapWidget
               tokenList={[
                {
                  "name": "CCRT",
                  "address": "0x4e9240604452d4454153bF59dE204E341186CC83", 
                  "symbol": "CCRT",
                  "decimals": 18,
                  "chainId": 137, 
                  "logoURI": "https://example.com/ccrt-logo.png"
                },
                {
                  "name": "USD Coin (PoS)",
                  "address": "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
                  "symbol": "USDC",
                  "decimals": 6,
                  "chainId": 137,
                  "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/assets/0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359/logo.png"
                }
              ]}
              defaultOutputTokenAddress="0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359" // USDC address
              defaultInputTokenAddress="0x4e9240604452d4454153bF59dE204E341186CC83"  // CCRT token address
              permits={["0x4e9240604452d4454153bF59dE204E341186CC83", "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359"]} // CCRT and USDC addresses

              width={"100%"}
            /> */}
            {/* <SwapWidget
              // tokenList={TOKEN_LIST}
              // provider={provider}
              // jsonRpcUrlMap={JSON_RPC_URL}
              // locale={locale}
              // onConnectWallet={focusConnectors}
              // defaultInputTokenAddress="NATIVE"
              // defaultInputAmount="1"
              // defaultOutputTokenAddress={UNI}
              width={"100%"}
              theme={"darkTheme"}
            /> */}
          </div>
        </div>
      )}

      {modalOpenThree && (
        <div className="ccrtngn-modal" onClick={handleModalCloseThree}>
          <div className="ccrtngn-modal__inner">
            <div className="ccrtngn-modal__inner__title">Amount</div>
            <div
              className={
                Number(ccrt) > 0
                  ? "ccrtngn-modal__inner__input ccrtngn-modal__inner__input-filled"
                  : "ccrtngn-modal__inner__input"
              }
            >
              <div className="ccrtngn-modal__inner__input__title">
                Input CCRT
              </div>
              <input
                type="text"
                value={ccrt}
                onChange={(e) => setCcrt(e.target.value)}
              />
            </div>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2"
                y="2"
                width="44"
                height="44"
                rx="14"
                stroke="white"
                stroke-width="4"
              />
              <rect
                x="3"
                y="3"
                width="42"
                height="42"
                rx="8"
                fill={Number(ccrt) > 0 ? "rgba(0, 126, 112, 0.20)" : "#F9F9F9"}
              />
              <path
                d="M23.2929 33.7071C23.6834 34.0976 24.3166 34.0976 24.7071 33.7071L31.0711 27.3431C31.4616 26.9526 31.4616 26.3195 31.0711 25.9289C30.6805 25.5384 30.0474 25.5384 29.6569 25.9289L24 31.5858L18.3431 25.9289C17.9526 25.5384 17.3195 25.5384 16.9289 25.9289C16.5384 26.3195 16.5384 26.9526 16.9289 27.3431L23.2929 33.7071ZM23 14V33H25V14H23Z"
                fill="black"
              />
            </svg>

            <div
              className={
                Number(ccrt) > 0
                  ? "ccrtngn-modal__inner__input ccrtngn-modal__inner__input-filled"
                  : "ccrtngn-modal__inner__input"
              }
            >
              <div className="ccrtngn-modal__inner__input__title">
                You receive
              </div>
              <input type="text" value={ccrt * 0.6 * 1500} disabled />
            </div>

            <button
              className={
                ccrt > 0
                  ? "ccrtngn-modal__inner__button ccrtngn-modal__inner__button-active"
                  : "ccrtngn-modal__inner__button"
              }
              onClick={handleModalOpenFour}
            >
              <div>Continue</div>
            </button>
          </div>
        </div>
      )}
      {modalOpenFour && (
        <div className="ccrtngn-modal" onClick={handleModalCloseThree}>
          <div className="ccrtngn-modal__recipient-inner">
            <div className="ccrtngn-modal__recipient-inner__title-group">
              <div>Recipient details</div>
              <div>Amount</div>
            </div>

            <div className="ccrtngn-modal__recipient-inner__details">
              <div>
                <div>Account name:</div>
                <div>{foundUser?.bankDetails?.bankFullName}</div>
              </div>
              <div>
                <div>Account number:</div>
                <div>{foundUser?.bankDetails?.accountNumber}</div>
              </div>
              <div>
                <div>Bank name:</div>
                <div>{foundUser?.bankDetails?.bankName}</div>
              </div>
              <div className="ccrtngn-modal__recipient-inner__details__divider"></div>
              <div className="ccrtngn-modal__recipient-inner__details__amount">
                <div>Amount:</div>
                <div>
                  NGN <span>{ccrt * 0.6 * 1500}</span>
                </div>
              </div>
            </div>

            <button
              className="ccrtngn-modal__inner__button ccrtngn-modal__inner__button-active"
              onClick={burnTokens}
            >
              <div>{loading ? "Loading..." : "Confirm"}</div>
            </button>
          </div>
        </div>
      )}

      {modalOpenFive && (
        <div className="errorModal" onClick={handleErrorModalClose}></div>
      )}

      {modalOpenFive && (
        <div className="error_modal">
          <div className="modal_title">Bank details missing</div>

          <div className="modal_subtitle">
            Please Fill in your bank details in the profile section before
            exchanging your CCRT
          </div>
          <button
            className="error_modal_button"
            type="submit"
            onClick={() => navigate("/profile")}
          >
            Go to profile
          </button>
        </div>
      )}

      {/* <Kyc /> */}
    </div>
  );
};

export default CarbonCredit;
