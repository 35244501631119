import axios from "axios";

export const getUserInfo = async (id, token) => {
  console.log("id", id);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/core/v1/get-user?user_id=${id}`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getBanks = async (country, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment/v1/list-banks?gateway=paystack&country=${country}`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const updateUserInfo = async (
  firstName,
  lastName,
  username,
  address,
  phoneNumber,
  countryCode,
  token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/core/v1/update-user-info?update=otherInfos`,
      {
        firstName,
        lastName,
        username,
        address,
        phoneNumber,
        countryCode,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const updateThirdPartyInfo = async (
  firstName,
  lastName,
  address,
  phoneNumber,
  idNumber,
  organization,
  token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.put(
      {
        firstName,
        lastName,
        address,
        phoneNumber,
        idNumber,
        organization,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const updateAdminInfo = async (
  firstName,
  lastName,
  email,
  profilePicture,
  token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/update-admin`,
      {
        firstName,
        lastName,
        email,
        profilePicture,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getKyc = async (id, token) => {
  console.log("id", id);
  console.log("token", token);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/kyc/v1/get-third-party-kyc?user_id=${id}`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const updatePassword = async (oldPassword, newPassword, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/core/v1/update-password`,
      {
        oldPassword,
        newPassword,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const verifyAccount = async (account_number, bank_code, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/payment/v1/create-bank-details`,
      {
        action: "verify_account_number",
        account_number,
        bank_code,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const saveBankDetails = async (
  account_number,
  user_id,
  bank_country,
  bank_code,
  token
) => {
  console.log({
    account_number,
    user_id,
    bank_country,
    bank_code,
    token,
  });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/payment/v1/create-bank-details`,
      {
        action: "create_recipient",
        account_number,
        user_id,
        bank_country,
        currency: "NGN",
        bank_code,
        payment_gateway: "paystack",
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const deactivateUser = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v1/deactivate-user`,
      {
        userId,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const demoRequest = async (
  firstName,
  lastName,
  email,
  phoneNumber,
  organization
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/demo-request`,
      {
        firstName,
        lastName,
        email,
        phoneNumber,
        organization,
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const submitSuggestion = async (title, message) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/submit-suggestion`,
      {
        title,
        message,
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const contactUs = async (name, email, phoneNumber, message) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/contact-us`,
      {
        name,
        email,
        phoneNumber,
        message,
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const joinUs = async (name, email, phoneNumber, coverLetter) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/join-us`,
      {
        name,
        email,
        phoneNumber,
        coverLetter,
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const sendFakeData = async (
  scenario,
  deviceId,
  solarType,
  renewableSource,
  token
) => {
  console.log({ scenario, deviceId, solarType, renewableSource });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/core/v1/generate-dummy-data`,
      {
        scenario,
        deviceId,
        solarType,
        renewableSource,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response;
  }
};
export const getStatistics = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v1/platform-statistics`
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const activate2fa = async (code, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/core/v1/enable-2fa`,
      {
        twoFa: code,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const makereq = async (
  deviceId,
  value,
  facilityType,
  vValue,
  batteryLevel,
  baseCalcApraoch,
  token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/core/v1/submit-emission-data`,
      {
        deviceId,
        value,
        facilityType,
        vValue,
        batteryLevel,
        baseCalcApraoch,
        token,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const deactivate2fa = async (code, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/core/v1/disable-2fa`,
      {
        twoFa: code,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const submitTemplate = async (
  facilityCategory,
  subscriptionPlan,
  firstName,
  lastName,
  email,
  companyName,
  industry,
  facilityCapacity,
  walletAddress
) => {
  try {
    console.log({
      facilityCategory,
      subscriptionPlan,
      firstName,
      lastName,
      email,
      companyName,
      industry,
      facilityCapacity,
      walletAddress,
    });
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/create-basic-plan-kyc`,
      {
        facilityCategory,
        subscriptionPlan,
        firstName,
        lastName,
        email,
        companyName,
        industry,
        facilityCapacity,
        walletAddress,
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const submitGovernanceTemplate = async (
  firstName,
  lastName,
  email,
  country,
  governmentOrganization,
  industry,
  facilityCategory,
  regulatoryStatus,
  walletAddress,
  subscriptionPlan
) => {
  console.log({
    firstName,
    lastName,
    email,
    country,
    governmentOrganization,
    industry,
    facilityCategory,
    regulatoryStatus,
    walletAddress,
    subscriptionPlan,
  });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/create-governance-plan-kyc`,
      {
        firstName,
        lastName,
        email,
        country,
        governmentOrganization,
        industry,
        facilityCategory,
        regulatoryStatus,
        walletAddress,
        subscriptionPlan,
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const submitBusinessTemplate = async (
  facilityCategory,
  subscriptionPlan,
  firstName,
  lastName,
  email,
  companyName,
  industry,
  facilityCapacity,
  country,
  subCcv,
  submission_by,
  walletAddress,
  token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    console.log({
      facilityCategory,
      subscriptionPlan,
      firstName,
      lastName,
      email,
      companyName,
      industry,
      facilityCapacity,
      country,
      subCcv,
      submission_by,
      walletAddress,
    });
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/business/v1/create-business?submission_by=${submission_by}`,
      {
        facilityCategory,
        subscriptionPlan,
        firstName,
        lastName,
        email,
        companyName,
        industry,
        facilityCapacity,
        country,
        subCcv,
        walletAddress,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const payBusinessPlan = async (email, planType, paymentGateway, callBackUrl) => {
  try {
    console.log({
      email,
      planType,
      paymentGateway,
      callBackUrl
    });
    const res = await axios.post(
      // `https://api.closecarbon.com/payment/v1/confirm-bank-details?action=sub_plan_payment`,
      `${process.env.REACT_APP_API_URL}/payment/v1/confirm-bank-details?action=sub_plan_payment`,
      {
        email,
        planType,
        paymentGateway,
        callBackUrl
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const cancelBusinessPlan = async (token) => {
  console.log("yoooo");
  
  console.log({token});
  
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/payment/v1/confirm-bank-details?action=disable_subscription`,
      {
        user: "Saleem"
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const submitCcvTemplate = async (
  subscriptionPlan,
  verifierType,
  firstName,
  lastName,
  email,
  organizationName,
  organizationAddress,
  organizationEmail,
  organizationRegistrationNumber,
  walletAddress,
  agreedToFollowProtocol,
  phoneNumber
) => {
  console.log({
    subscriptionPlan,
    verifierType,
    firstName,
    lastName,
    email,
    organizationName,
    organizationAddress,
    organizationEmail,
    organizationRegistrationNumber,
    walletAddress,
    agreedToFollowProtocol,
    phoneNumber,
  });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/verifier/v1/create-verifier-plan-kyc`,
      {
        subscriptionPlan,
        verifierType,
        firstName,
        lastName,
        email,
        organizationName,
        organizationAddress,
        organizationEmail,
        organizationRegistrationNumber,
        walletAddress,
        agreedToFollowProtocol,
        phoneNumber,
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const submitSubCcvTemplate = async (
  subscriptionPlan,
  verifierType,
  firstName,
  lastName,
  email,
  phoneNumber,
  verificationBody,
  walletAddress,
  agreedToFollowProtocol
) => {
  console.log(
    subscriptionPlan,
    verifierType,
    firstName,
    lastName,
    email,
    phoneNumber,
    verificationBody,
    walletAddress,
    agreedToFollowProtocol
  );
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/verifier/v1/create-verifier-plan-kyc`,
      {
        subscriptionPlan,
        verifierType,
        firstName,
        lastName,
        email,
        phoneNumber,
        verificationBody,
        walletAddress,
        agreedToFollowProtocol,
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getBlog = async (id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/open/v1/blog-details?blog_id=${id}`
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const submitNdcRequest = async (requestPurpose, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/ndc/v1/request-ndc-value`,
      {
        requestPurpose,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getNdcValue = async (token, from, to) => {
  console.log("wakanda", parseInt(from), parseInt(to));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    if (parseInt(from) > 0 && parseInt(to) > 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/ndc/v1/get-ndc-value?from=${from}&end=${to}`,
        config
      );

      return res;
    } else {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/ndc/v1/get-ndc-value?from=&end=`,
        config
      );

      return res;
    }
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getNdcStatus = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/ndc/v1/confirm-ndc-request`,
      {
        message: "hello",
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const verifyDonation = async (transactionId, paymentGateway, email) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/payment/v1/confirm-bank-details`,
      {
        transactionId,
        paymentGateway,
        email,
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const donateFund = async (
  transactionId,
  donatorType,
  firstName,
  lastName,
  businessName,
  email,
  phoneNumber,
  identityConsent,
  city,
  state,
  zipCode,
  country
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/donation/v1/user/donate-fund`,
      {
        transactionId,
        donatorType,
        firstName,
        lastName,
        businessName,
        email,
        phoneNumber,
        identityConsent,
        city,
        state,
        zipCode,
        country,
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const sendDonationCertificate = async (email, link, name) => {
  console.log({
    email,
    link,
    name,
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/donation/v1/certificate-notification`,
      {
        email,
        link,
        name,
      }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
