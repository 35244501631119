import { useEffect, useState } from "react";
import Footer from "../../components/shared/Footer";
import Header from "../../components/shared/Header";
import "../../styles/shared/Videos.scss";
import { getStatistics } from "../../apis/General/users";

const videoData = [
    {
      title: "CloseCarbon at a glance",
      subtitle: "Discover how CloseCarbon brings real-time carbon monitoring, impactful plans, and sustainable solutions together in one powerful platform.",
      iframeSrc: "https://www.youtube.com/embed/ppPfMr0nerY?autoplay=0",
      category: "Overview"
    },
    {
      title: "Explore the Basic plan",
      subtitle: "Learn about the key features, ideal organizations, and how to get started with CloseCarbon's Basic Plan.",
      iframeSrc: "https://www.youtube.com/embed/yyxGaFNoDws?autoplay=0",
      category: "Basic Plan"
    },
    {
      title: "Discover the Governance plan",
      subtitle: "Learn about the benefits, registration process, and the organizations that are best suited for CloseCarbon's Governance Plan.",
      iframeSrc: "https://www.youtube.com/embed/On9PHYLfZOk?autoplay=0",
      category: "Governance Plan"
    },
    {
      title: "Introducing the Verifier plan (CCV)",
      subtitle: "Understand the features, registration process, and the organizations best suited for CloseCarbon's Verifier Plan (CCV).",
      iframeSrc: "https://www.youtube.com/embed/xK5PMog-SMA?autoplay=0",
      category: "Verifier Plan"
    },
    {
      title: "Unlock the Sub-Verifier plan",
      subtitle: "Learn about the Sub-CCV plan's features, the types of organizations that benefit, and how to get started with registration.",
      iframeSrc: "https://www.youtube.com/embed/La7YpdVFFiQ?autoplay=0",
      category: "Sub-Verifier Plan"
    },
    {
      title: "Understanding CloseCarbon Voluntary Donation pool",
      subtitle: "Learn how to donate, why your contribution matters, and how it helps drive sustainability and carbon reduction efforts.",
      iframeSrc: "https://www.youtube.com/embed/zNU3i1cOjIY?autoplay=0",
      category: "Donations"
    },
    {
      title: "Exchange Closecarbon incentive (CCRTs) with ease",
      subtitle: "Learn how to exchange your earned CCRT for Nigerian Naira or USDT once your facility meets the required carbon emission standards.",
      iframeSrc: "https://www.youtube.com/embed/AXa5Wm9xf5I?autoplay=0",
      category: "Exchange"
    }
  ];

export default function Videos() {
    const [text, setText] = useState("")
    const [textType, setTextType] = useState("")
    const [results, setResults] = useState("");
    const [emissionTracked, setEmissionTracked] = useState(0);


    useEffect(() => {
        if(textType === "category") {
            const temp = videoData?.filter((item) => item.category?.toLowerCase() === text?.toLowerCase())
            setResults([...temp])
        }else {
            const temp = videoData?.filter((item) => item?.title?.toLowerCase()?.includes(text?.toLowerCase()) || item.subtitle?.toLowerCase()?.includes(text?.toLowerCase()) || item.category?.toLowerCase()?.includes(text?.toLowerCase()))
        setResults([...temp])

        }
        
    }, [text, textType]);

    const getStats = async () => {
          const response = await getStatistics();
          setEmissionTracked(response?.data?.statistics?.totalEmission?.toFixed(0))
    }

    useEffect(() => {
      getStats();
    }, [])
  return (
    <>
      <Header />
      <main className="shared-videos">
        <div className="shared-videos__group">
          <div>
            <div className="shared-videos__group__title">
              CloseCarbon Videos
            </div>
            <div className="shared-videos__group__subtitle">
              Discover CloseCarbon, Your Way!
            </div>
          </div>

          <div>
            <div className="shared-videos__group__plan">
             <div>"Find the right fits for your goals...</div>
              <button>Choose a plan</button>
            </div>

            <div className="shared-videos__group__stats">
              Over 872+ satisfied clients and {emissionTracked === 1000000 ? "Over 1million" : Math.round(emissionTracked * 1000)}+kgCO2 emissions tracked
            </div>
            <div className="shared-videos__group__stats-mini">
              98% of them think CloseCarbon is Great!
            </div>
          </div>
        </div>
        <div className="shared-videos__search">
            <select name="" id=""
            onChange={(e) => setText(e.target.value)}
            >
                <option value="">All</option>
                <option value="Overview">Overview</option>
                <option value="Basic Plan">Basic Plan</option>
                <option value="Overview">Overview</option>
                <option value="Governance Plan">Governance Plan</option>
                <option value="Verifier Plan">Verifier Plan</option>
                <option value="Sub-Verifier Plan">Sub-Verifier Plan</option>
                <option value="Donations">Donations</option>
                <option value="Exchange">Exchange</option>
            </select>
            <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z" stroke="#B1B1B1" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            <input type="text" placeholder="Search video here..." value={text} onChange={(e) => setText(e.target.value)} />
            </div>
        </div>
{text?.length < 1 && <>
        <div className="shared-videos__grid">
          <div className="shared-videos__grid__card">
            <iframe
              src="https://www.youtube.com/embed/ppPfMr0nerY?autoplay=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>

            <div className="shared-videos__grid__card__title">
              CloseCarbon at a glance
            </div>

            <div className="shared-videos__grid__card__subtitle">
              Discover how CloseCarbon brings real-time carbon monitoring,
              impactful plans, and sustainable solutions together in one
              powerful platform.
            </div>
          </div>
          <div className="shared-videos__grid__card">
            <iframe
              width="100%"
              height="293"
              src="https://www.youtube.com/embed/yyxGaFNoDws?autoplay=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>

            <div className="shared-videos__grid__card__title">
              Explore the Basic plan
            </div>

            <div className="shared-videos__grid__card__subtitle">
              Learn about the key features, ideal organizations, and how to get
              started with CloseCarbon’s Basic Plan.
            </div>
          </div>
        </div>
        <div className="shared-videos__grid">
          <div className="shared-videos__grid__card">
            <iframe
              width="100%"
              height="293"
              src="https://www.youtube.com/embed/On9PHYLfZOk?autoplay=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>

            <div className="shared-videos__grid__card__title">
              Discover the Governance plan
            </div>

            <div className="shared-videos__grid__card__subtitle">
              Learn about the benefits, registration process, and the
              organizations that are best suited for CloseCarbon’s Governance
              Plan.
            </div>
          </div>
          <div className="shared-videos__grid__card">
            <iframe
              width="100%"
              height="293"
              src="https://www.youtube.com/embed/xK5PMog-SMA?autoplay=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>

            <div className="shared-videos__grid__card__title">
              Introducing the Verifier plan (CCV)
            </div>

            <div className="shared-videos__grid__card__subtitle">
              Understand the features, registration process, and the
              organizations best suited for CloseCarbon’s Verifier Plan (CCV).
            </div>
          </div>
        </div>
        <div className="shared-videos__grid">
          <div className="shared-videos__grid__card">
            <iframe
              width="100%"
              height="293"
              src="https://www.youtube.com/embed/La7YpdVFFiQ?autoplay=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>

            <div className="shared-videos__grid__card__title">
              Unlock the Sub-Verifier plan
            </div>

            <div className="shared-videos__grid__card__subtitle">
              Learn about the Sub-CCV plan’s features, the types of
              organizations that benefit, and how to get started with
              registration.
            </div>
          </div>
          <div className="shared-videos__grid__card">
            <iframe
              width="100%"
              height="293"
              src="https://www.youtube.com/embed/zNU3i1cOjIY?autoplay=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>

            <div className="shared-videos__grid__card__title">
              Understanding CloseCarbon Voluntary Donation pool
            </div>

            <div className="shared-videos__grid__card__subtitle">
              Learn how to donate, why your contribution matters, and how it
              helps drive sustainability and carbon reduction efforts.
            </div>
          </div>
        </div>
        <div className="shared-videos__grid">
          <div className="shared-videos__grid__card">
            <iframe
              width="100%"
              height="293"
              src="https://www.youtube.com/embed/AXa5Wm9xf5I?autoplay=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>

            <div className="shared-videos__grid__card__title">
            Exchange Closecarbon incentive (CCRTs) with ease
            </div>

            <div className="shared-videos__grid__card__subtitle">
            Learn how to exchange your earned CCRT for Nigerian Naira or USDT once your facility meets the required carbon emission standards.
            </div>
          </div>
        </div>
        </>}

        {text?.length > 0 &&<div className="shared-videos__grid">
          {results?.map((result) => 
        <div className="shared-videos__grid__card">
        <iframe
          src={result?.iframeSrc}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>

        <div className="shared-videos__grid__card__title">
          {result?.title}
        </div>

        <div className="shared-videos__grid__card__subtitle">
          {result?.subtitle}
        </div>
      </div>
        )}

        </div>}
      </main>
      <Footer />
    </>
  );
}
