import "../../styles/UserDashboard/Signup.scss";
import {
  Box,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import { getDatabase, push, ref } from "firebase/database";
import { pdf } from "@react-pdf/renderer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { countries } from "../../data/countries";
import { useNavigate, useParams } from "react-router-dom";
import {
  registerEmail,
  resendOtp,
  signUp,
  verifyEmail,
} from "../../apis/UserDashboard/authentication";
import { toast } from "react-toastify";
import { getUserInfo } from "../../apis/General/users";
import SignatureCanvas from "react-signature-canvas";
import SlaAgreement from "../ThirdParty/SlaAgreement";
import moment from "moment";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useParams();
  const db = getDatabase();

  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [sla, setSla] = useState(false);
  const [stage, setStage] = useState(2);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [personName, setPersonName] = useState("");
  const [country, setCountry] = useState("country");
  const [inst1, setInst1] = useState("");
  const [inst2, setInst2] = useState("");
  const [inst3, setInst3] = useState("");
  const [inst4, setInst4] = useState("");
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorButtonAction, setErrorButtonAction] = useState("");
  const [firstSignature, setFirstSignature] = useState(null);
  const [secondSignature, setSecondSignature] = useState(null);
  const [signatureModal, setSignatureModal] = useState(false);
  const [signatureStage, setSignatureStage] = useState(1);

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  const passwordRegex1 = /^.{8,}/;
  const passwordRegex2 = /(?=.*[a-z])(?=.*[A-Z])/;
  const passwordRegex3 = /(?=.*\d)/;
  const passwordRegex4 = /(?=.*[!@#$%^&*])/;
  useEffect(() => {
    if (password?.length > 0) {
      if (passwordRegex1.test(password)) {
        setInst1("true");
      } else {
        console.log("yooooxcxv");

        setInst1("false");
      }
      if (passwordRegex2.test(password)) {
        setInst2("true");
      } else {
        setInst2("false");
      }
      if (passwordRegex3.test(password)) {
        setInst3("true");
      } else {
        setInst3("false");
      }
      if (passwordRegex4.test(password)) {
        setInst4("true");
      } else {
        setInst4("false");
      }
    } else {
      setInst1("");
      setInst2("");
      setInst3("");
      setInst4("");
    }
  }, [password]);

  useEffect(() => {
    const otpInputs = document.querySelectorAll(".otp_input");

    otpInputs.forEach((input, index) => {
      input.addEventListener("input", (event) => {
        const inputValue = event.target.value;

        if (inputValue && index < otpInputs.length - 1) {
          otpInputs[index + 1].focus();
        }
      });

      input.addEventListener("keydown", (event) => {
        if (event.key === "Backspace" && index > 0 && !input.value) {
          otpInputs[index - 1].focus();
          event.preventDefault();
        }
      });
    });
  }, [stage === 2]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleErrorModalOpen = () => {
    setErrorModalOpen(true);
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("modal")) {
      setModalOpen(false);
    }
  };
  const handleErrorModalClose = (e) => {
    if (e.target.classList.contains("errorModal")) {
      setErrorModalOpen(false);
    }
  };

  const handleShowError = (title, body, action = "ok") => {
    setErrorTitle(title);
    setErrorText(body);
    setErrorButtonAction(action);
    handleErrorModalOpen();
  };

  const clearOtpInput = () => {
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");
    setOtp6("");
  };

  const handleEmailSubmit = async (e) => {
    console.log("rer");
    setLoading(true);
    e.preventDefault();
    const response = await registerEmail(email, "user");
    console.log("response", response);
    if (response?.data?.statusCode === 200) {
      if (
        response?.data?.message ===
        "This email has been registered. Please input the otp sent to your email to activate or click resend OTP to get a new one"
      ) {
        // alert(
        //   "This email has been registered. Please input the otp sent to your email to activate or request for a new OTP"
        // );
        handleShowError(
          "Oops email already existed",
          "This email already exists. Kindly click the button below to input the otp sent to your email to activate or request for a new OTP"
        );
      }
      setStage(2);
    } else if (
      response?.data?.message ===
      "This email has been registered. Kindly complete the sign up process"
    ) {
      // alert("This email has been registered. Complete the sign up process");
      handleShowError(
        "Oops email already registered",
        "It appears this email has already been registered.  Kindly click the button below to complete the sign up process"
      );
      setStage(3);
    } else if (
      response?.data?.message ===
      "Action not allowed. Your demo request submission has not been approved. Kindly wait for an approval"
    ) {
      handleShowError(
        "Action Not Allowed",
        "Your demo request submission has not been approved. Kindly wait for an approval"
      );
    } else if (
      response?.data?.message ===
      "Access to the platform denied. Kindly submit a request for demo to beging your registration process"
    ) {
      handleShowError(
        "Access Denied",
        "Access to the platform denied. Kindly submit a request for demo to beging your registration process",
        "Request demo"
      );
    } else if (response?.data?.statusCode === 400) {
      handleShowError(
        "Oops email already registered",
        "It appears this email has already been registered. If you’ve forgotten your password, click “Forgot Password” on the login screen.",
        "login"
      );
    }
    setLoading(false);
  };
  const handleOTPSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const otp = `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`;
    console.log("email", email);
    const response = await verifyEmail(otp, email);
    console.log("response", response);
    if (response?.data?.statusCode === 200) {
      setStage(3);
    } else if (
      response?.data?.message === "Token used. Please, proceed to sign up"
    ) {
      alert(response?.data?.message);
      setStage(3);
    } else if (response?.data?.statusCode === 401) {
      // handleShowError("OTP expired", "The one-time code you entered has expired. Request a new OTP to complete your registration.", "Request OTP");
      setOtpError(true);
    }
    setLoading(false);
  };
  const handlePersonalDetailsSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    setStage(4);
    setLoading(false);
  };
  const handlePasswordSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    //Regex for a 8 characters long password that contains at least one upper and lower case letter, a number and a symbol
    let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (password !== confirmPassword) {
      // alert("Passwords do not match");
      handleShowError(
        "Passwords do not match",
        "The passwords you entered do not match. Please check and try again"
      );
      setLoading(false);
    } else if (password === confirmPassword && !re.test(confirmPassword)) {
      // alert(
      //   "Your password must be at least 8 characters long and contain at least one upper and lower case letter, a number and a symbol"
      // );
      handleShowError(
        "Passwords does not meet requirement",
        "Your password must be at least 8 characters long and contain at least one upper and lower case letter, a number and a symbol"
      );
    } else {
      setStage(5);
    }
    setLoading(false);
  };
  const handleComapnyDetailsSubmit = (e) => {
    e.preventDefault();
    setStage(6);
  };

  const handleSignup = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!terms) {
      alert("Please agree to our terms and conditions");
    } else if (!privacy) {
      alert("Please agree to our privacy");
    } else {
      const response = await signUp(
        email,
        firstName,
        lastName,
        password,
        "user",
        phoneNumber,
        country,
        countryCode,
        companyName,
        address
      );

      if (response?.data?.statusCode === 200) {
        localStorage.setItem("token", response?.data?.message);

        dispatch({
          type: "REGISTER_SUCCESS",
          payload: {
            token: response?.data?.message,
          },
        });

        // push(ref(db, "notifications"), {
        //   type: "Incomplete Profile",
        //   read: false,
        //   user: pathname?.id,
        //   timestamp: Date.now(),
        // });
        // push(ref(db, "kycStatus"), {
        //   status: "pending",
        //   user: pathname?.id,
        //   timestamp: Date.now(),
        // });
        navigate("/dashboard");
        window.scrollTo(0, 0);
      } else {
        toast.error(response?.data?.message);
      }
    }

    setLoading(false);
  };

  const handleResendEmail = async (e) => {
    setLoading(true);
    e.preventDefault();
    const response = await resendOtp(email);
    console.log("resend", response);
    if (response?.data?.statusCode === 200) {
      setModalOpen(false);
      clearOtpInput();
      setOtpError(false);
    } else if (
      response?.data?.message ===
      "Email verified! Please complete your sign-up."
    ) {
      toast.error(response?.data?.message);

      setModalOpen(false);
      clearOtpInput();
      setOtpError(false);
      setStage(3);
    } else {
      toast.error(response?.data?.message);
    }
    setLoading(false);
  };

  const handleCountryChange = (e, v) => {
    e.preventDefault();

    setLoading(true);
    setCountry(v?.label);
    setLoading(false);
  };

  const fetchUserInfo = async () => {
    setLoading(true);
    const response = await getUserInfo(pathname?.id);
    console.log("response getUserInfo", response);
    setEmail(response?.data?.message?.email);
    setFirstName(response?.data?.message?.firstName);
    setLastName(response?.data?.message?.lastName);
    setCompanyName(response?.data?.message?.facilityName);
    setAddress(response?.data?.message?.facilityLocation);
    setLoading(false);
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  // useEffect(() => {
  //   if(pathname?.stage){
  //     setStage(parseInt(pathname?.stage));
  //   }
  // }, [])
  let firstSignaturePad = {};
  let secondSignaturePad = {};

  const handleSign = () => {
    if (
      (signatureStage === 1 && firstSignaturePad.isEmpty()) ||
      (signatureStage === 2 && secondSignaturePad.isEmpty())
    ) {
      alert("Please provide a signature");
    } else {
      if (signatureStage === 1) {
        const signatureData = firstSignaturePad.toDataURL();
        console.log("signatureData", signatureData);
        setFirstSignature(signatureData);
        setSignatureStage(2);
      } else {
        const signatureData = secondSignaturePad.toDataURL();
        console.log("signatureData", signatureData);
        setSecondSignature(signatureData);
        handlePdf(firstSignature, signatureData);
      }
    }
  };

  const generatePdfBlob = async () => {
    const doc = (
      <SlaAgreement
        // firstSignature={signature1}
        // secondSignature={signature2}
        // companyName={companyName}
        // address={address}
      />
    );
    const asPdf = pdf([]);
    console.log("here1");

    asPdf.updateContainer(doc);
    console.log("here2");

    try {
      const blob = await asPdf.toBlob();
      return blob;
    } catch (error) {
      console.log("errror createing blob", error);
    }
    console.log("here3");
  };

  const handlePdf = async () => {
    try {
      // setLoading(true)
      const blob = await generatePdfBlob();

      // For downloading
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `-SLA ${moment(
        Date.now()
      ).format("DD-MM-YYYY")}.pdf`;
      link.click();
      setSla(true);
      setSignatureModal(false);
      // // For uploading
      // const file = new File(
      //   [blob],
      //   `${
      //  `${firstName} ${lastName}`
      // }-SLA ${moment(Date.now()).format(
      //   "DD-MM-YYYY"
      // )}.pdf`,
      //   { type: "application/pdf" }
      // );

      // // Create a mock event object
      // const mockEvent = {
      //   target: {
      //     files: [file],
      //   },
      // };

      // // Call the fileUpload function
      // const response = await fileUpload(mockEvent, "donation_certificate");
      // console.log("fileUpload", response);

      // return response;
      // // setLoading(false)
    } catch (error) {
      console.log("error creating certificate:", error);
      return {
        status: "error creating certificate",
        statusCode: 500,
      };
    }
  };

  useEffect(() => {
    console.log('handlePdf');
    
    handlePdf();
  }, [])

  return (
    <div className="signup">
      <div className="signup_inner">
        <div>
          {stage === 1 && (
            <div className="title">
              Shaping a Sustainable Tomorrow, Today...
            </div>
          )}
          {stage === 2 && (
            <div className="title">
              Shaping a Sustainable Tomorrow, Today...
            </div>
          )}
          {stage === 3 && (
            <div className="title">
              A World with Reduced Carbon Emissions...
            </div>
          )}
          {stage === 4 && (
            <div className="title">
              A World with Reduced Carbon Emissions...
            </div>
          )}
          {stage === 5 && (
            <div className="title">Driving Environmental Change...</div>
          )}
          {stage === 6 && (
            <div className="title">A Green Approach to Carbon Reduction...</div>
          )}
          <div className="dots">
            <div
              className={stage === 1 || stage === 2 ? "dot_active dot" : "dot"}
            ></div>
            <div className={stage === 3 ? "dot_active dot" : "dot"}></div>
            <div className={stage === 5 ? "dot_active dot" : "dot"}></div>
            <div className={stage === 6 ? "dot_active dot" : "dot"}></div>
          </div>
        </div>
        {/* Signup step 1(email) */}
        {stage === 1 && (
          <div className="box">
            <div className="signup_link">
              Already have an account?{" "}
              <span
                className="pointer"
                onClick={() => {
                  navigate("/login");
                  window.scrollTo(0, 0);
                }}
              >
                Login
              </span>
            </div>
            <div className="box_title">Create an account</div>
            <div className="box_subtitle">
              Kindly complete all steps to create an account
            </div>
            <div className="steps">
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <form onSubmit={handleEmailSubmit}>
              <InputLabel className="signup_label" htmlFor="email">
                Email address
              </InputLabel>

              <OutlinedInput
                className={error ? "signup_input signup_error" : "signup_input"}
                required
                id="email"
                type="email"
                placeholder="Enter email address"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError(false);
                }}
              />
              {error && (
                <div className="signup_error_text">
                  Email taken. Please input another one
                </div>
              )}

              <button
                className="signup_submit"
                type="submit"
                disabled={loading}
              >
                <div>{loading ? "Loading..." : "Continue"}</div>
              </button>
            </form>
          </div>
        )}
        {/* Signup step 2(email validation) */}
        {stage === 2 && (
          <form className="box" onSubmit={handleOTPSubmit}>
            <div className="box_title_verify box_title">
              Verify your email address
            </div>
            <div className="box_subtitle_verify box_subtitle">
              We sent a 5-digit-code to your email address.
            </div>
            <div className="box_subtitle_verify box_subtitle bold">
              Kindly input the code below.
            </div>
            <div className="otp_box_group">
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp1}
                  name="otp1"
                  onChange={(e) => {
                    setOtp1(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>

              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp2}
                  name="otp2"
                  onChange={(e) => {
                    setOtp2(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp3}
                  name="otp3"
                  onChange={(e) => {
                    setOtp3(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp4}
                  name="otp4"
                  onChange={(e) => {
                    setOtp4(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp5}
                  name="otp5"
                  onChange={(e) => {
                    setOtp5(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp6}
                  name="otp6"
                  onChange={(e) => {
                    setOtp6(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
            </div>

            {otpError && (
              <div className="otp_error_text">Invalid or Expired OTP</div>
            )}

            <button className="signup_submit" type="submit" disabled={loading}>
              <div>{loading ? "Loading..." : "Verify"}</div>
            </button>
            <div className="error didnt_get pointer" onClick={handleModalOpen}>
              Nah, I didn’t get the code
            </div>
            <div className="didnt_get">
              <span className="didnt_get__error">Note:</span> Please check your{" "}
              <span>Spam filter</span> or <span>Junk mail</span> folder if you
              don’t receive an email us.
            </div>
            {modalOpen && (
              <form className="email_modal">
                <div className="modal_subtitle bold">
                  Why are you not getting the code:
                </div>

                <ul>
                  <li>Check your spam folder.</li>
                  <li>Incorrect email address</li>
                </ul>
                <InputLabel className="modal_label" htmlFor="reenter-email">
                  Re-enter email address
                </InputLabel>

                <OutlinedInput
                  // className={
                  //   errorPresent ? "login-inputs login-error" : "login-inputs"
                  // }
                  className="modal_input"
                  required
                  id="reenter-email"
                  type="text"
                  value={email}
                  placeholder="Enter email address"
                  name="reenter-email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    // setErrorPresent(false);
                  }}
                />
                <button
                  className="modal_button"
                  type="submit"
                  onClick={handleResendEmail}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Resend Code"}
                </button>
              </form>
            )}
          </form>
        )}

        {/* Signup step 3(basic details) */}
        {stage === 3 && (
          <div className="box">
            <div className="box_title">Personal details</div>
            <div className="box_subtitle">
              Kindly complete all steps to create an account
            </div>
            <div className="steps">
              <div className="circle_active circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <form onSubmit={handlePersonalDetailsSubmit}>
              <InputLabel className="signup_label" htmlFor="firstName">
                First name
              </InputLabel>

              <OutlinedInput
                className="signup_input"
                required
                id="firstName"
                type="text"
                placeholder="Enter first name"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <InputLabel className="signup_label" htmlFor="lastName">
                Last name
              </InputLabel>

              <OutlinedInput
                className="signup_input"
                required
                id="lastName"
                type="text"
                placeholder="Enter last name"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />

              <button
                className="signup_submit"
                type="submit"
                disabled={loading}
              >
                <div>{loading ? "Loading..." : "Continue"}</div>
              </button>
            </form>
          </div>
        )}
        {stage === 4 && (
          <div className="box">
            <div className="box_title">Create password</div>
            <div className="box_subtitle">
              Kindly complete all steps to create an account
            </div>
            <div className="steps">
              <div className="circle_active circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <form onSubmit={handlePasswordSubmit}>
              <InputLabel className="signup_label" htmlFor="password">
                Password
              </InputLabel>

              <OutlinedInput
                className="signup_input"
                required
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <InputLabel
                className="signup_label passowrd_input"
                htmlFor="confirmPassword"
              >
                Confirm password
              </InputLabel>
              <OutlinedInput
                className="signup_input"
                required
                id="confirmPassword"
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div className="instructions_title">Password must:</div>
              <div
                className={
                  inst1 === "true"
                    ? "instructions isFilled"
                    : inst1 === "false"
                    ? "instructions notFilled"
                    : "instructions"
                }
              >
                {inst1 === "true" ? (
                  <svg
                    viewBox="0 0 117 117"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title></title> <desc></desc> <defs></defs>{" "}
                      <g
                        fill="none"
                        fill-rule="evenodd"
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                      >
                        {" "}
                        <g fill-rule="nonzero" id="correct">
                          {" "}
                          <path
                            d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z"
                            fill="#17AB13"
                            id="Shape"
                          ></path>{" "}
                          <path
                            d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z"
                            fill="#17AB13"
                            id="Shape"
                          ></path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                ) : (
                  <svg
                    
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4"
                      cy="4"
                      r="3.75"
                      stroke="#667185"
                      stroke-width="0.5"
                    />
                    <line
                      x1="2"
                      y1="3.75"
                      x2="6"
                      y2="3.75"
                      stroke="#667185"
                      stroke-width="0.5"
                    />
                  </svg>
                )}
                Password must be at least 8 characters long{" "}
              </div>
              <div
                className={
                  inst2 === "true"
                    ? "instructions isFilled"
                    : inst2 === "false"
                    ? "instructions notFilled"
                    : "instructions"
                }
              >
                {inst2 === "true" ? (
                  <svg
                    viewBox="0 0 117 117"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title></title> <desc></desc> <defs></defs>{" "}
                      <g
                        fill="none"
                        fill-rule="evenodd"
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                      >
                        {" "}
                        <g fill-rule="nonzero" id="correct">
                          {" "}
                          <path
                            d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z"
                            fill="#17AB13"
                            id="Shape"
                          ></path>{" "}
                          <path
                            d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z"
                            fill="#17AB13"
                            id="Shape"
                          ></path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                ) : (
                  <svg
                    
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4"
                      cy="4"
                      r="3.75"
                      stroke="#667185"
                      stroke-width="0.5"
                    />
                    <line
                      x1="2"
                      y1="3.75"
                      x2="6"
                      y2="3.75"
                      stroke="#667185"
                      stroke-width="0.5"
                    />
                  </svg>
                )}
                Contain at least one upper and lower case letter{" "}
              </div>
              <div
                className={
                  inst3 === "true"
                    ? "instructions isFilled"
                    : inst3 === "false"
                    ? "instructions notFilled"
                    : "instructions"
                }
              >
                {inst3 === "true" ? (
                  <svg
                    viewBox="0 0 117 117"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title></title> <desc></desc> <defs></defs>{" "}
                      <g
                        fill="none"
                        fill-rule="evenodd"
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                      >
                        {" "}
                        <g fill-rule="nonzero" id="correct">
                          {" "}
                          <path
                            d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z"
                            fill="#17AB13"
                            id="Shape"
                          ></path>{" "}
                          <path
                            d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z"
                            fill="#17AB13"
                            id="Shape"
                          ></path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                ) : (
                  <svg
                    
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4"
                      cy="4"
                      r="3.75"
                      stroke="#667185"
                      stroke-width="0.5"
                    />
                    <line
                      x1="2"
                      y1="3.75"
                      x2="6"
                      y2="3.75"
                      stroke="#667185"
                      stroke-width="0.5"
                    />
                  </svg>
                )}
                A number{" "}
              </div>
              <div
                className={
                  inst4 === "true"
                    ? "instructions isFilled"
                    : inst4 === "false"
                    ? "instructions notFilled"
                    : "instructions"
                }
              >
                <span>
                  {inst4 === "true" ? (
                    <svg
                      viewBox="0 0 117 117"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <title></title> <desc></desc> <defs></defs>{" "}
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                        >
                          {" "}
                          <g fill-rule="nonzero" id="correct">
                            {" "}
                            <path
                              d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z"
                              fill="#17AB13"
                              id="Shape"
                            ></path>{" "}
                            <path
                              d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z"
                              fill="#17AB13"
                              id="Shape"
                            ></path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  ) : (
                    <svg
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="4"
                        cy="4"
                        r="3.75"
                        stroke="#667185"
                        stroke-width="0.5"
                      />
                      <line
                        x1="2"
                        y1="3.75"
                        x2="6"
                        y2="3.75"
                        stroke="#667185"
                        stroke-width="0.5"
                      />
                    </svg>
                  )}
                </span>
                A symbol{" "}
              </div>

              <button
                className="signup_submit"
                type="submit"
                disabled={loading}
              >
                <div>{loading ? "Loading..." : "Continue"}</div>
              </button>
            </form>
          </div>
        )}
        {/* Signup step 4(company details) */}
        {stage === 5 && (
          <div className="box">
            <div className="box_title">Organisation details</div>
            <div className="box_subtitle">
              Kindly complete all steps to create an account
            </div>
            <div className="steps">
              <div className="circle_active circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle circle_active">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <form onSubmit={handleComapnyDetailsSubmit}>
              <InputLabel className="signup_label" htmlFor="companyName">
                Company name
              </InputLabel>

              <OutlinedInput
                className="signup_input"
                required
                id="companyName"
                type="text"
                placeholder="Enter company name"
                name="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <InputLabel className="signup_label" htmlFor="address">
                Address
              </InputLabel>

              <OutlinedInput
                className="signup_input"
                required
                id="address"
                type="text"
                placeholder="Enter company address"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <InputLabel className="signup_label" htmlFor="phoneNumber">
                Country code & Phone number
              </InputLabel>
              <div className="signup_input_group">
                <OutlinedInput
                  className="signup_input_mini"
                  required
                  id="countryCode"
                  type="number"
                  placeholder="+234"
                  name="countryCode"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                />
                <div>
                  <OutlinedInput
                    className="signup_input"
                    required
                    id="phoneNumber"
                    type="number"
                    placeholder="Enter company phone number"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <InputLabel className="signup_label" htmlFor="country">
                Country
              </InputLabel>
              <Autocomplete
                id="country"
                className="signup_input"
                fullWidth
                placeholder="Nigeria"
                sx={{ fontSize: "30px !important" }}
                // sx={{ mb: "32px", marginTop: "-20px" }}
                options={countries}
                onChange={handleCountryChange}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Country"
                    className="signup_input"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    sx={{
                      fontSize: "30px !important",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                      },
                    }}

                    // variant="standard"
                  />
                )}
              />
              <button
                className="signup_submit"
                type="submit"
                disabled={loading}
              >
                <div>{loading ? "Loading..." : "Continue"}</div>
              </button>
            </form>
          </div>
        )}
        {stage === 6 && (
          <form className="box" onSubmit={handleSignup}>
            <div className="box_title">Terms & Conditions</div>
            <div className="box_subtitle">
              Kindly complete all steps to create an account
            </div>
            <div className="steps">
              <div className="circle_active circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle circle_active">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div className="circle_active circle">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="line"></div>
              <div
                className={
                  terms && privacy === true ? "circle_active circle" : "circle"
                }
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="signup_checkbox_group">
              {!terms && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setTerms(true)}
                >
                  <rect
                    x="1"
                    y="1"
                    width="16"
                    height="16"
                    rx="4"
                    stroke="url(#paint0_linear_2889_18043)"
                    stroke-width="1.33333"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2889_18043"
                      x1="1"
                      y1="1.36719"
                      x2="20.0689"
                      y2="10.7886"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#006633" />
                      <stop offset="1" stop-color="#02A4CC" />
                    </linearGradient>
                  </defs>
                </svg>
              )}

              {terms && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setTerms(false)}
                >
                  <rect
                    x="1"
                    y="1"
                    width="16"
                    height="16"
                    rx="4"
                    stroke="url(#paint0_linear_2889_18037)"
                    stroke-width="1.33333"
                  />
                  <path
                    d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325"
                    stroke="url(#paint1_linear_2889_18037)"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2889_18037"
                      x1="1"
                      y1="1.36719"
                      x2="20.0689"
                      y2="10.7886"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#006633" />
                      <stop offset="1" stop-color="#02A4CC" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2889_18037"
                      x1="5.5"
                      y1="6.948"
                      x2="12.5203"
                      y2="11.8039"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#006633" />
                      <stop offset="1" stop-color="#02A4CC" />
                    </linearGradient>
                  </defs>
                </svg>
              )}
              <div>
                By registering, I confirm that I have read the{" "}
                <span
                  className="pointer"
                  onClick={() => {
                    window.open(
                      "/terms-and-conditions",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  Terms and Conditions
                </span>
              </div>
            </div>

            <div className="signup_checkbox_group">
              {!privacy && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setPrivacy(true)}
                >
                  <rect
                    x="1"
                    y="1"
                    width="16"
                    height="16"
                    rx="4"
                    stroke="url(#paint0_linear_2889_18043)"
                    stroke-width="1.33333"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2889_18043"
                      x1="1"
                      y1="1.36719"
                      x2="20.0689"
                      y2="10.7886"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#006633" />
                      <stop offset="1" stop-color="#02A4CC" />
                    </linearGradient>
                  </defs>
                </svg>
              )}

              {privacy && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setPrivacy(false)}
                >
                  <rect
                    x="1"
                    y="1"
                    width="16"
                    height="16"
                    rx="4"
                    stroke="url(#paint0_linear_2889_18037)"
                    stroke-width="1.33333"
                  />
                  <path
                    d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325"
                    stroke="url(#paint1_linear_2889_18037)"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2889_18037"
                      x1="1"
                      y1="1.36719"
                      x2="20.0689"
                      y2="10.7886"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#006633" />
                      <stop offset="1" stop-color="#02A4CC" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2889_18037"
                      x1="5.5"
                      y1="6.948"
                      x2="12.5203"
                      y2="11.8039"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#006633" />
                      <stop offset="1" stop-color="#02A4CC" />
                    </linearGradient>
                  </defs>
                </svg>
              )}

              <div>
                By registering, I confirm that I have read the{" "}
                <span
                  className="pointer"
                  onClick={() => {
                    window.open(
                      "/privacy-policy",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  Privacy Notice
                </span>
              </div>
            </div>
            {/* <div className="signup_checkbox_group">
              {!sla && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  // onClick={() => setTerms(true)}
                  onClick={() => setSignatureModal(true)}
                >
                  <rect
                    x="1"
                    y="1"
                    width="16"
                    height="16"
                    rx="4"
                    stroke="url(#paint0_linear_2889_18043)"
                    stroke-width="1.33333"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2889_18043"
                      x1="1"
                      y1="1.36719"
                      x2="20.0689"
                      y2="10.7886"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#006633" />
                      <stop offset="1" stop-color="#02A4CC" />
                    </linearGradient>
                  </defs>
                </svg>
              )}

              {sla && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setSla(false)}
                >
                  <rect
                    x="1"
                    y="1"
                    width="16"
                    height="16"
                    rx="4"
                    stroke="url(#paint0_linear_2889_18037)"
                    stroke-width="1.33333"
                  />
                  <path
                    d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325"
                    stroke="url(#paint1_linear_2889_18037)"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2889_18037"
                      x1="1"
                      y1="1.36719"
                      x2="20.0689"
                      y2="10.7886"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#006633" />
                      <stop offset="1" stop-color="#02A4CC" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2889_18037"
                      x1="5.5"
                      y1="6.948"
                      x2="12.5203"
                      y2="11.8039"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#006633" />
                      <stop offset="1" stop-color="#02A4CC" />
                    </linearGradient>
                  </defs>
                </svg>
              )}
              <div>
                By registering, I confirm that I have read the{" "}
                <span
                  className="pointer"
                  onClick={() => {
                    window.open("/sla", "_blank", "noopener,noreferrer");
                  }}
                >
                  SLA
                </span>
              </div>
            </div> */}

            <button className="signup_submit" type="submit" disabled={loading}>
              <div>{loading ? "Loading..." : "Signup"}</div>
              {!loading && (
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5459 10.7959L8.79594 14.5459C8.58459 14.7573 8.29795 14.876 7.99906 14.876C7.70018 14.876 7.41353 14.7573 7.20219 14.5459C6.99084 14.3346 6.87211 14.0479 6.87211 13.7491C6.87211 13.4502 6.99084 13.1635 7.20219 12.9522L9.03125 11.125H1.25C0.951631 11.125 0.665483 11.0065 0.454505 10.7955C0.243526 10.5845 0.125 10.2984 0.125 10C0.125 9.70163 0.243526 9.41548 0.454505 9.2045C0.665483 8.99353 0.951631 8.875 1.25 8.875H9.03125L7.20406 7.04594C7.09942 6.94129 7.0164 6.81706 6.95977 6.68033C6.90314 6.5436 6.87399 6.39706 6.87399 6.24906C6.87399 5.95018 6.99272 5.66353 7.20406 5.45219C7.41541 5.24084 7.70205 5.12211 8.00094 5.12211C8.29982 5.12211 8.58647 5.24084 8.79781 5.45219L12.5478 9.20219C12.6526 9.30683 12.7356 9.43112 12.7923 9.56793C12.8489 9.70474 12.8779 9.85139 12.8778 9.99945C12.8776 10.1475 12.8482 10.2941 12.7912 10.4308C12.7343 10.5675 12.6509 10.6915 12.5459 10.7959ZM17 0.625H11.75C11.4516 0.625 11.1655 0.743526 10.9545 0.954505C10.7435 1.16548 10.625 1.45163 10.625 1.75C10.625 2.04837 10.7435 2.33452 10.9545 2.5455C11.1655 2.75647 11.4516 2.875 11.75 2.875H16.625V17.125H11.75C11.4516 17.125 11.1655 17.2435 10.9545 17.4545C10.7435 17.6655 10.625 17.9516 10.625 18.25C10.625 18.5484 10.7435 18.8345 10.9545 19.0455C11.1655 19.2565 11.4516 19.375 11.75 19.375H17C17.4973 19.375 17.9742 19.1775 18.3258 18.8258C18.6775 18.4742 18.875 17.9973 18.875 17.5V2.5C18.875 2.00272 18.6775 1.52581 18.3258 1.17417C17.9742 0.822544 17.4973 0.625 17 0.625Z"
                    fill="white"
                  />
                </svg>
              )}
            </button>
          </form>
        )}
      </div>

      {modalOpen && <div className="modal" onClick={handleModalClose}></div>}
      {errorModalOpen && (
        <div className="errorModal" onClick={handleErrorModalClose}></div>
      )}
      {errorModalOpen && (
        <div className="error_modal">
          <div className="modal_title">{errorTitle}</div>

          <div className="modal_subtitle">{errorText}</div>
          {errorButtonAction === "login" ? (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => navigate("/login")}
              disabled={loading}
            >
              {loading ? "Loading..." : "Login"}
            </button>
          ) : errorButtonAction === "Request OTP" ? (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => {
                setErrorModalOpen(false);
                handleModalOpen();
              }}
              disabled={loading}
            >
              {loading ? "Loading..." : "Request new OTP"}
            </button>
          ) : errorButtonAction === "Request demo" ? (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => navigate("/demo")}
              disabled={loading}
            >
              {loading ? "Loading..." : "Request demo"}
            </button>
          ) : (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => setErrorModalOpen(false)}
              disabled={loading}
            >
              {loading ? "Loading..." : "Okay"}
            </button>
          )}
        </div>
      )}
      {signatureModal && (
        <div className="signup__signature-modal">
          <div className="signup__signature-modal__inner">
            {signatureStage === 1 && (
              <div className="signup__signature-modal__inner__title">
                Sign as Director 1
              </div>
            )}
            {signatureStage === 2 && (
              <div className="signup__signature-modal__inner__title">
                Sign as Director 2/Company secretary
              </div>
            )}
            {signatureStage === 1 && (
              <SignatureCanvas
                ref={(ref) => {
                  firstSignaturePad = ref;
                }}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas",
                }}
              />
            )}
            {signatureStage === 2 && (
              <SignatureCanvas
                ref={(ref) => {
                  secondSignaturePad = ref;
                }}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas",
                }}
              />
            )}
            <button onClick={handleSign}>Sign</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
